import ApplicationController from '../../application_controller'
import consumer from "../../../channels/consumer";
import CableReady from "cable_ready";
import {
    cryptoToUsd,
    getRate,
    usdToCrypto,
    subscribeBitgoChannel,
    convertUsdToUserCurrency,
    setupClickOutside,
    setButtonStyle
} from "../../../lib/flip/helpers";

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
var cryptoCoins = [];
let controller;
export default class extends ApplicationController {

    static targets = ["address", "standardButton", "autoconvertButton", "rateSpanInfo", "receivePage",
        "maxBalanceAmountFrom", "maxBalanceAmountFromUserCurrency", "maxBalanceAmountTo",
        "maxBalanceAmountToUserCurrency", "periodicWithdrawalButton"]

    connect(){
        super.connect();
        if (this.hasReceivePageTarget) {
            controller = this;
            this.autoconvert = false;
            cryptoCoins = this.receivePageTarget.getAttribute('data-crypto-coins')?.split(',') || [];
            if (process.env.NODE_ENV != "production") {
                cryptoCoins.push("TUSD*");
            }
            setupClickOutside();
            this.subscribeAddressChannel();
            this.listenAddressUpdate();
            subscribeBitgoChannel(controller);
        }
    }

    subscribeAddressChannel() {
        consumer.subscriptions.create("AddressChannel", {
            received(data) {
                if (data.cableReady) CableReady.perform(data.operations)
            }
        });
    }

    listenAddressUpdate() {
        this.element.addEventListener("address-updated",
            (e) => {
            this.stimulate("Users::Wallets::ReceiveReflex#show_address_section", e.target);
        })
    }

    afterSelectedCoin(e){
        let selector = e.getAttribute('data-coin-type');
        if (selector != "to_convert")
            this.autoconvert = false;
    }

    beforeToggleTabSection(e){
        let tabs = [this.standardButtonTarget, this.autoconvertButtonTarget, this.periodicWithdrawalButtonTarget];
        setButtonStyle(tabs, e);
        if(this.hasAddressTarget)
            this.listenAddressUpdate();
    }

    // update max balance amounts in user currency showing in coins selector
    updateBalanceInfo(){
        if (this.autoconvert){
            let maxBalanceAmountTo = parseFloat(this.maxBalanceAmountToTarget.innerText);
            let symbolTo = this.maxBalanceAmountToTarget.getAttribute('data-symbol');
            let maxBalanceAmountToUsd = cryptoToUsd(maxBalanceAmountTo, getRate(symbolTo, "sell_price"));
            this.maxBalanceAmountToUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.receivePageTarget, maxBalanceAmountToUsd);
        }
        let maxBalanceAmountFrom = parseFloat(this.maxBalanceAmountFromTarget.innerText);
        let symbolFrom = this.maxBalanceAmountFromTarget.getAttribute('data-symbol');
        let maxBalanceAmountFromUsd = cryptoToUsd(maxBalanceAmountFrom, getRate(symbolFrom, "sell_price"));
        this.maxBalanceAmountFromUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.receivePageTarget, maxBalanceAmountFromUsd);
    }

    // CryptoToUsd use sell_price to get rate
    // usdToCrypto use buy_price to get rate
    calculateRate(value, type, symbolFrom, symbolTo){
        if (type == "to_receive"){
            let usdAmount = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(value, getRate(symbolFrom, "sell_price")) : value
            let cryptoValue = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));

            return cryptoCoins.includes(symbolTo) ? [value, usdAmount, cryptoValue, type] : [value, usdAmount, usdAmount, type] ;
        }
    }

}
