import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["active", "search"];
  connect() {
    super.connect();
    window.avatarUploaded = this.avatarUploaded;
  }

  editName() {
    const element = this.element;
    const inputName = element.querySelector("#nameInput");
    element.setAttribute("open", "");
    inputName.removeAttribute("disabled");
    inputName.value = "";
    inputName.focus();
  }

  changeActiveShop(e) {
    if (!!this.hasActiveTarget) {
      const value = e.target.dataset.value;
      const parent = e.target.parentNode;
      parent.toggleAttribute("open");
      this.activeTarget.setAttribute('value', value);
    }
  }

  updateUserAvatar() {
    document.getElementById("filestack-avatar").click()
  }

  avatarUploaded(data) {
    let target = document.getElementById("filestack-avatar")
    let payload = JSON.stringify(data.filesUploaded[0])

    let imageTag = document.querySelector("#shop-avatar-container > img")
    imageTag.setAttribute("src", data.filesUploaded[0]['url'])

    target.dataset.avatarPayload = payload
    target.dispatchEvent(new Event("avatar_uploaded"))
  }

  filterBySearch() {
    if (this.searchTarget.value) this.search_form(this.searchTarget);
  }

  search_form(target = event.currentTarget) {
    this.stimulate("EcommerceReflex#filter_by_search", target);
  }
}
