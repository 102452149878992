import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btn"];
  static values = { link: String };

  copy_link() {
    navigator.clipboard.writeText(this.linkValue);
    this.element.setAttribute("open", "");
    setTimeout(() => {
      this.element.removeAttribute("open");
    }, 2000);
  }
}
