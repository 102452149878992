import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    this.showBanner = () => {
      this.element.classList.remove("hidden");
      this.element.classList.add("flex");
    };

    this.hideBanner = () => {
      this.element.classList.add("hidden");
      this.element.classList.remove("flex");
    };
  }

  connect() {
    window.addEventListener("online", this.hideBanner);
    window.addEventListener("offline", this.showBanner);
  }

  disconnect() {
    window.removeEventListener("online", this.hideBanner);
    window.removeEventListener("offline", this.showBanner);
  }
}
