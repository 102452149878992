import ApplicationController from ".././application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.openModalWithDelay();
  }

  openModalWithDelay() {
    if (!this.modal || !this.verificationCompleted) return;

    let defaultDelay = this.modal.dataset.assistantDelay;

    setTimeout(() => this.modal.classList.remove("hidden"), defaultDelay);
  }

  openModal(e) {
    e.preventDefault();
    if (!this.offCanvasMenu.classList.contains("hidden"))
      this.offCanvasMenu.classList.add("hidden");

    let delay = e.target.dataset.assistantDelay || 0;

    setTimeout(() => this.modal.classList.remove("hidden"), delay);
  }

  closeModal(e) {
    e.preventDefault();
    this.modal.classList.add("hidden");
    if (this.verificationCompleted)
      window.location = window.location.href.split("?")[0];
  }

  get offCanvasMenu() {
    return document.getElementById("off-canvas-menu");
  }

  get verificationCompleted() {
    return this.modal.dataset.verificationCompleted;
  }

  get modal() {
    return document.getElementById("account-assistant");
  }
}
