import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["top", "bottom", "content"];

  initialize() {
    this.onScroll = (e) => {
      let target = e.target;
      let scrollTop = target.scrollTop;
      let scrollHeight = target.scrollHeight;
      let offsetHeight = target.offsetHeight;
      switch (scrollTop) {
        case 0:
          this.topTarget.className = "hidden shadow-top";
          this.bottomTarget.className = "shadow-bottom";
          break;

        case scrollHeight - offsetHeight:
          this.topTarget.className = "shadow-top";
          this.bottomTarget.className = "hidden shadow-bottom";
          break;

        default:
          this.topTarget.className = "shadow-top";
          this.bottomTarget.className = "shadow-bottom";
      }
    };
  }
  connect() {
    if (this.hasContentTarget) {
      this.contentTarget.addEventListener("scroll", this.onScroll);
    }
  }

  disconnect() {
    if (this.hasContentTarget) {
      this.contentTarget.removeEventListener("scroll", this.onScroll);
    }
  }
}
