import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"];

  initialize() {
    var controller = this;
    var $form = $(this.formTarget);

    $form.on('ajax:success', function(event) {
      $(event.target).trigger('reset');
    });
    $form.on('ajax:error', function (event) {
      console.log('withdrawal - error');
      controller.clearErrors();
      controller.showErrors(event.detail);
    });
  }

  showErrors(details) {
    var $form = $(this.formTarget);
    var [errors, status, xhr] = details;
    $form.find(".errors-wrapper").show();
    var errorsList = $form.find('.errors-list');
    for(var i=0; i<errors.length; i++) {
      errorsList.append($("<li></li>").text(errors[i]))
    }
  }

  clearErrors() {
    $(this.formTarget).find('.errors-list').empty();
  }
}
