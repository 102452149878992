import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["coinSelect", "toCoin", "amount", "toAmount"]

  connect() {
    super.connect()
    $(this.coinSelectTarget).dropdown()
    this._initAmountCleave()
  }

  afterUpdateSwap() {
    var precision = parseInt(this.toCoinTarget.dataset.precision)
    this._initToAmountCleave(precision)
  }

  _initAmountCleave() {
    if (this.hasAmountTarget){
      new Cleave(
        this.amountTarget,
        {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: "none",
          numeralDecimalScale: this.data.get("precision")
        }
      )
    }
  }

  _initToAmountCleave(precision) {
    if (this.hasToAmountTarget){
      new Cleave(
        this.toAmountTarget,
        {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: "none",
          numeralDecimalScale: precision
        }
      )
    }
  }
}

