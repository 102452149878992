import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["transactionCard", "transactionCommentsPayload"]
  static values = { 
    transactionCardExpanded: Boolean,
    transactionCardEditing: Boolean
  }

  connect(){
    super.connect();
    let controller = this;

    document.getElementsByName("comments-hidden-field").forEach(commentsField =>{
      let fieldId = commentsField.dataset.transactionId;
      window[`porUploaded${fieldId}`] = function(data){
        controller.porUploaded(fieldId, data);
      };
    })
  }

  porUploaded(fieldId, data) {
    let target = document.getElementById(`comments-${fieldId}`)
    let commentsLink = document.getElementById(`comments-link-${fieldId}`)

    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event("por_uploaded"))
    commentsLink.href = data.filesUploaded[0]['url']
    commentsLink.classList.remove('hidden')
  }

  transactionCardToggleExpanded(){
    if (this.hasTransactionCardTarget){
      this.transactionCardExpandedValue = !this.transactionCardExpandedValue;
      this.transactionCardEditingValue = false;
    }
    if (this.hasTransactionCommentsPayloadTarget){
      this.transactionCommentsPayloadTarget.classList.toggle('hidden')
    }
  }

  transactionCardExpandedValueChanged(){
    let transactionCard = this.transactionCardTarget;
    let sectionDetails = transactionCard.querySelector("#card-section-details");
    let expandIndicator = transactionCard.querySelector("[data-card-expand-indicator]");
    let inputCommet = transactionCard.querySelector("#card-section-comments-write input");
    sectionDetails.classList.toggle('hidden', !this.transactionCardExpandedValue);
    expandIndicator.classList.toggle('rotate-180', !this.transactionCardExpandedValue);
    inputCommet?.classList.toggle('pointer-events-none', !this.transactionCardExpandedValue);
    inputCommet?.classList.toggle('border-b', this.transactionCardExpandedValue);
    inputCommet?.classList.toggle('hidden', !this.transactionCardExpandedValue && !inputCommet.value.length);
    
  }

  wantsEditComments(){
    this.transactionCardEditingValue = true;
  }

  wantsSaveComments(){
    let transactionCard = this.transactionCardTarget;
    let inputComments = transactionCard.querySelector("[data-card-input-comments]");
    this.stimulate('Components::Wallet::TransactionCardReflex#save_comments', inputComments);
  }

  afterReflex (element, reflex){
    let transactionCard = this.transactionCardTarget;
    let textComments = transactionCard.querySelector("[data-card-text-comments]");
    let notificationSaveComment = this.element.querySelector('.notificationSaveComment');
    notificationSaveComment.classList.toggle('opacity-0');
    notificationSaveComment.classList.toggle('hidden');
    setTimeout(()=>{
      notificationSaveComment.classList.toggle('opacity-0');
      notificationSaveComment.classList.toggle('hidden');
    }, 2000)
    textComments.childNodes[0].nodeValue = element.value;      
    this.transactionCardEditingValue = false;
  }
}
