import { Controller } from '@hotwired/stimulus'
import filestack from 'filestack-js';
import axios from 'axios';
import csrf_token from '../csrf_token'

export default class extends Controller {
  static targets = ["sessionLengthTypeSelect", "sessionLengthInputWrapper"]

  connect(){
    // Axios setup
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token()
    axios.defaults.baseURL = '/u/settings'

    this.selectSessionLengthType()
  }

  uploadAvatar(event) {
    event.preventDefault();

    // Filestack Implementation
    let fsik = document.getElementById('settings-account-form').dataset.fsik;
    let fsClient = filestack.init(fsik);

    fsClient.pick({
      fromSources:[
        "local_file_system"
      ],
      accept:["image/*"],
      transformations: {
        crop: {
          aspectRatio: 1,
          force: true
        },
        rotate: true
      },
      maxFiles:1,
      minFiles:1
    }).then(function(response) {

      // Patch request to AvatarController
      axios.patch('/avatar', { avatar: { avatar_payload: response.filesUploaded[0] } })
        .then(function (status) {

          // Update account Avatar Imgs src
          document.getElementById('account-avatar-img').src = response.filesUploaded[0]['url'];
          document.getElementById('primary-nav-avatar-img').src = response.filesUploaded[0]['url'];

          // Change 'Upload' to 'Change'
          document.getElementById('upload-avatar-button').innerHTML = "Change";

          // Show 'Remove' button
          document.getElementById('destroy-avatar-button').classList.remove("invisible");
          document.getElementById('destroy-avatar-button').classList.add("visible");
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  destroyAvatar(event) {
    event.preventDefault();

    // Delete request to AvatarController
    axios({
      method: 'delete',
      url: '/avatar'
    }).then(() => {
      // Change 'Change' to 'Upload'
      document.getElementById('upload-avatar-button').innerHTML = "Upload";

      // Hide 'Remove' button
      document.getElementById('destroy-avatar-button').classList.remove("visible");
      document.getElementById('destroy-avatar-button').classList.add("invisible");

      // Delete request to AvatarController
      axios.get('/base_image')
        .then(function (response) {
          // Update account Avatar Imgs src
          document.getElementById('account-avatar-img').src = response.data.url;
          document.getElementById('primary-nav-avatar-img').src = response.data.url;
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  selectSessionLengthType() {
    if(!this.hasSessionLengthInputWrapperTarget) return;

    const wrapper = this.sessionLengthInputWrapperTarget,
          lengthType = this.sessionLengthTypeSelectTarget.selectedOptions[0].value

    if(lengthType === "default"){
      wrapper.classList.add("invisible");
    } else {
      wrapper.classList.remove("invisible");
    }
  }
}
