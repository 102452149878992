import ApplicationController from '../../application_controller'
import {Subject} from "rxjs";
import {map} from "rxjs/operators";
import {
    cryptoToUsd,
    getRate,
    usdToCrypto,
    subscribeBitgoChannel,
    convertUsdToUserCurrency,
    userCurrencyToUsd,
    setupClickOutside,
    setButtonStyle,
    setAmountButtonStyle,
    setNewValueToTarget,
    getDataValue
} from "../../../lib/flip/helpers";
import QrScanner from 'qr-scanner';

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
var cryptoCoins = [];
let controller;
export default class extends ApplicationController {

    static targets = ["address", "standardButton", "autoconvertButton",
        "rateSpanInfo", "sendPage", "amountFrom", "amountTo", "amountToSymbol",
        "amountFromSymbol", "amountFromUserCurrency", "amountToUserCurrency", 
        "amountToInputUserCurrency", "amountFromInputUserCurrency",
        "maxBalance", "halfBalance", "toSendAmount", "fromAmount",
        "maxBalanceAmountTo", "maxBalanceAmountFrom", "maxBalanceAmountFromUserCurrency",
        "maxBalanceAmountToUserCurrency", "fee", "periodicWithdrawalButton", "savedWallet",
        "periodicWithdrawalStandardButton", "periodicWithdrawalSecuredButton", "periodicWithdrawalSendType"]

    amountInputObserver$ = new Subject();

    initialize() {
        this.activateSendTriggers = (event) => {
            this.updateCoinSummary();
        }
        this.changeHiddenAmountValue = (event) => {
            this.updateHiddenAmountValue();
        }
        this.autocompleteWallet =  (event) => {
            this.autocompleteWallet(event.detail);
        }
        this.playSendSound = (event) => {
            var sound = new Audio(document.getElementById('send-audio').src);
            sound.preload = 'auto';
            sound.load();
            sound.play();
        }
    }

    connect() {
        super.connect();
        if (this.hasSendPageTarget) {
            controller = this;
            this.autoconvert = false;
            cryptoCoins = this.sendPageTarget.getAttribute('data-crypto-coins').split(',');
            if (process.env.NODE_ENV != "production") {
                cryptoCoins.push("TUSD*");
            }
            setupClickOutside();
            this.amountToTarget.setAttribute('data-fixed', "true");
            // setup observable amount inputs
            this.setupAmountInput();
            subscribeBitgoChannel(controller);
        }
        document.addEventListener("activate-send-triggers", this.activateSendTriggers)
        document.addEventListener("change-hidden-amount-value", this.changeHiddenAmountValue)
        document.addEventListener("autocomplete-wallet", this.autocompleteWallet)
        document.addEventListener("play-send-sound", this.playSendSound)
    }

    disconnect() {
        document.removeEventListener("activate-send-triggers", this.activateSendTriggers);
        document.removeEventListener("change-hidden-amount-value", this.changeHiddenAmountValue);
        document.removeEventListener("autocomplete-wallet", this.autocompleteWallet);
        document.removeEventListener("play-send-sound", this.playSendSound);
    }

    cleanInputValues() {
        if (this.hasAmountFromTarget) {
            this.amountFromTarget.value = "";
            this.amountFromInputUserCurrencyTarget.value = "";
        }
        this.amountToTarget.value = "";
        this.amountToInputUserCurrencyTarget.value = "";
    }

    updateValues() {
        let target = document.querySelector('[data-fixed="true"]');
        // notify to observer
        if (target != null && !Number.isNaN(Number(target.value)))
            this.amountInputObserver$.next(target);
    }

    afterSelectedCoin(e) {
        let selector = e.getAttribute('data-coin-type');
        if (selector != "to_convert")
            this.autoconvert = false;

        this.cleanInputValues();
    }

    afterModalConfirmOpen() {
        this.updateUserCurrencyValues();
        this.feeTargets.forEach((t) => t.innerText = this.calculateFee());
    }

    beforeToggleTabSection(e) {
        let tabs = [this.standardButtonTarget, this.autoconvertButtonTarget, this.periodicWithdrawalButtonTarget];
        setButtonStyle(tabs, e);
        this.updateValues();

        let autoConvert = e == this.autoconvertButtonTarget;
        this.savedWalletTargets.forEach((savedWallet) => {
            savedWallet.dataset['autoConvert'] = autoConvert
        })
    }

    afterSendEmailCode(){
        const emailCodeButton = document.getElementById("email-code-button");
        emailCodeButton.innerText = emailCodeButton.getAttribute("data-sendlabel");
    }

    setBalanceValue(e) {
        e.preventDefault();
        let precision = parseInt(getDataValue(this.amountToTarget, "precision"));
        let value = parseFloat(getDataValue(e.target, "value")).toFixed(precision);
        let currency_value = parseFloat(getDataValue(e.target, "currency-value"));       
                        
        setAmountButtonStyle(this, e.target);
        setNewValueToTarget(this.amountToTarget, value);        
        setNewValueToTarget(this.amountToInputUserCurrencyTarget, currency_value);   
        const cryptoInput = document.getElementById("swap_operation_amount_to");
        cryptoInput.value = value;        
        this.amountInputObserver$.next(this.amountToTarget);

        this.updateCoinSummary();
        this.enableSendButton();
    }

    updateCoinSummary(){
        let sendCoin = document.querySelector("#send-coin [data-coin-id]");
        sendCoin = sendCoin === null ? null : sendCoin.attributes['data-coin-id'].value;

        let toConvertCoin = document.querySelector("#auto-convert-coin [data-coin-id]");
        toConvertCoin = toConvertCoin === null ? null : toConvertCoin.attributes['data-coin-id'].value;

        let value = document.querySelector('#swap_operation_amount_to').value;

        const sendForm = document.getElementById("send-page");
        this.stimulate("Users::Wallets::SendReflex#update_coin_summary", sendForm, sendCoin, toConvertCoin, value)
    }

    updateBalanceInfo() {
        if (this.hasAmountFromTarget && this.hasMaxBalanceAmountFromTarget) {
            let maxBalanceAmountFrom = parseFloat(this.maxBalanceAmountFromTarget.innerText);
            let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
            let maxBalanceAmountFromUsd = cryptoToUsd(maxBalanceAmountFrom, getRate(symbolFrom, "sell_price"));
            this.maxBalanceAmountFromUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.sendPageTarget, maxBalanceAmountFromUsd);
        }

        let maxBalanceAmountTo = parseFloat(this.maxBalanceAmountToTarget.innerText);
        let symbolTo = this.amountToTarget.getAttribute('data-symbol');
        let maxBalanceAmountToUsd = cryptoToUsd(maxBalanceAmountTo, getRate(symbolTo, "buy_price"));
        this.maxBalanceAmountToUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.sendPageTarget, maxBalanceAmountToUsd);
    }

    calculateRate(value, type, symbolFrom, symbolTo) {
        if (type == "to_send") {
            let usdAmount = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(value, getRate(symbolFrom, "sell_price")) : value
            let cryptoValue = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));

            return cryptoCoins.includes(symbolTo) ? [value, usdAmount, cryptoValue, type] : [value, usdAmount, usdAmount, type];
        }
    }

    setupAmountInput() {
        this.amountInputObserver$.pipe(
            map(e => {                
                let value = e.value == "" ? "0" : e.value;
                let type = e.getAttribute('data-type');
                let symbolFrom = this.hasAmountFromTarget ? this.amountFromTarget.getAttribute('data-symbol') : null;
                let symbolTo = this.amountToTarget.getAttribute('data-symbol');

                return this.calculateAmountFromInputs(value, type, symbolFrom, symbolTo);
            })
        ).subscribe(result => {            
            // update DOM
            // result = [originValue, usdValue, destinationValue, type]
            let precisionFrom = this.hasAmountFromTarget ? this.amountFromTarget.getAttribute('data-precision') : 0;
            let precisionTo = this.amountToTarget.getAttribute('data-precision');

            if (this.autoconvert) {
                // Case typing input crypto
                if (result[3] == "to_send") {
                    this.amountFromTarget.value = parseFloat(result[0]).toFixed(precisionFrom);
                    this.toSendAmountTargets.forEach((el) => el.innerText = parseFloat(result[2]).toFixed(precisionTo));
                    this.fromAmountTarget.innerText = parseFloat(result[0]).toFixed(precisionFrom);
                } else {
                    // Case typing input user currency
                    this.amountFromTarget.value = parseFloat(result[0]).toFixed(precisionFrom);
                    this.amountToTarget.value = parseFloat(result[2]).toFixed(precisionTo);
                    this.toSendAmountTargets.forEach((el) => el.innerText = parseFloat(result[2]).toFixed(precisionTo));
                    this.fromAmountTarget.innerText = parseFloat(result[0]).toFixed(precisionFrom);
                }
            } else {
                // Case typing input crypto
                if (result[3] == "to_send") {
                    let value = parseFloat(result[2]).toFixed(precisionTo);
                    this.toSendAmountTargets.forEach((el) => el.innerText = value);
                } else {
                    // Case typing input user currency
                    this.amountToTarget.value = parseFloat(result[2]).toFixed(precisionTo);
                    this.toSendAmountTargets.forEach((el) => el.innerText = parseFloat(result[2]).toFixed(precisionTo));
                }
            }
            this.feeTarget.innerText = this.calculateFee();
            this.updateUserCurrencyValues();
            this.enableSendButton();
        })

    }

    calculateFee() {
        let withdrawalFee = parseFloat(this.amountToTarget.getAttribute('data-fee'));
        let fee = parseFloat(this.amountToTarget.value) * withdrawalFee;
        let precision = this.amountToTarget.getAttribute('data-precision');

        return Number.isNaN(Number(fee)) ? 0 : fee.toFixed(precision);
    }

    // this amount includes fee to ensure send the real value that the user wants
    amountOperation(value = null) {
        let withdrawalFee = parseFloat(this.amountToTarget.getAttribute('data-fee'));
        let userValue = value !== null ? value : parseFloat(this.amountToTarget.value);

        return withdrawalFee > 0 ? (userValue / (1 - withdrawalFee)) : userValue;
    }

    // return [originValue, usdValue, destinationValue, type]
    // CryptoToUsd use sell_price to get rate
    // usdToCrypto use buy_price to get rate
    // if autoconvert require add withdrawal fee to amount from convert using this.amountOperation()
    calculateAmountFromInputs(value, type, symbolFrom, symbolTo) {
        if (this.autoconvert) {
            if (type == "to_send") {
                // Case typing input crypto
                let usdAmount = cryptoCoins.includes(symbolTo) ? cryptoToUsd(this.amountOperation(), getRate(symbolTo, "sell_price")) : this.amountOperation();
                let cryptoValueFrom = usdToCrypto(usdAmount, getRate(symbolFrom, "buy_price"));

                return cryptoCoins.includes(symbolTo) ? [cryptoValueFrom, usdAmount, value, type] : [usdAmount, usdAmount, value, type];
            } else {
                // Case typing input user currency
                let userCurrency = this.sendPageTarget.getAttribute('data-user-currency');
                let userCurrencyUsdRate = parseFloat(this.sendPageTarget.getAttribute('data-user-currency-usd-rate'));
                let usdAmount = userCurrency == "USD" ? value : userCurrencyToUsd(value, userCurrencyUsdRate);
                let cryptoValueTo = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));
                let usdAmountWithFee = cryptoCoins.includes(symbolTo) ? cryptoToUsd(this.amountOperation(cryptoValueTo), getRate(symbolTo, "sell_price")) : this.amountOperation(cryptoValueTo);
                let cryptoValueFrom = usdToCrypto(usdAmountWithFee, getRate(symbolFrom, "buy_price"));

                return cryptoCoins.includes(symbolTo) ? [cryptoValueFrom, usdAmount, cryptoValueTo, type] : [usdAmount, usdAmount, value, type];
            }
        } else {
            if (type == "to_send") {
                // Case typing input crypto
                let usdAmount = cryptoCoins.includes(symbolTo) ? cryptoToUsd(value, getRate(symbolTo, "sell_price")) : value;

                return [null, usdAmount, value, type]
            } else {
                // Case typing input user currency
                let userCurrency = this.sendPageTarget.getAttribute('data-user-currency');
                let userCurrencyUsdRate = parseFloat(this.sendPageTarget.getAttribute('data-user-currency-usd-rate'));
                let usdAmount = userCurrency == "USD" ? value : userCurrencyToUsd(value, userCurrencyUsdRate);
                let cryptoValueTo = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));

                return [null, usdAmount, cryptoValueTo, type];
            }
        }
    }

    updateUserCurrencyValues() {
        if (this.hasAmountFromTarget) {
            let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
            let amountFromUserCurrencyUsd = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(this.amountFromTarget.value, getRate(symbolFrom, "sell_price")) : this.amountFromTarget.value;
            let amountFromUserCurrency = convertUsdToUserCurrency(this.sendPageTarget, amountFromUserCurrencyUsd);
            this.amountFromUserCurrencyTargets.forEach((el) => el.innerText = amountFromUserCurrency);

            if (this.amountFromInputUserCurrencyTarget.getAttribute('data-fixed') == "false") {
                this.amountFromInputUserCurrencyTarget.value = amountFromUserCurrency;
            }
        }
        let symbolTo = this.amountToTarget.getAttribute('data-symbol');
        let amountToUserCurrencyUsd = cryptoCoins.includes(symbolTo) ? cryptoToUsd(this.amountToTarget.value, getRate(symbolTo, "sell_price")) : this.amountToTarget.value;
        if (this.hasSendPageTarget) {
            let amountToUserCurrency = convertUsdToUserCurrency(this.sendPageTarget, amountToUserCurrencyUsd);
            this.amountToUserCurrencyTargets.forEach((el) => el.innerText = amountToUserCurrency);

            if (this.amountToInputUserCurrencyTarget.getAttribute('data-fixed') == "false")
                this.amountToInputUserCurrencyTarget.value = amountToUserCurrency;
        }
    }

    enableSendButton() {
        const sendForm = document.getElementById("send-page");
        const inputAddressMessage = document.getElementById("input-address-message"); 
        const balanceFlag = inputAddressMessage.dataset.balanceFlag;

        this.stimulate("Users::Wallets::SendReflex#validate_address", sendForm, balanceFlag);
    }

    updateHiddenAmountValue() {
        let hiddenField = document.getElementById("hidden-amount");
        let AmountField = document.getElementById("periodic_withdrawal_amount_crypto");

        hiddenField.value = AmountField.value;
        hiddenField.dispatchEvent(new Event('input', {}));
    }

    autocompleteWallet(data) {
        this.addressTarget.value = data.address
    }

    updatePeriodicWithdrawalSendType(event) {
        this.periodicWithdrawalSendTypeTarget.value = event.target.value;

        let tabs = [this.periodicWithdrawalStandardButtonTarget, this.periodicWithdrawalSecuredButtonTarget];
        setButtonStyle(tabs, event.target);

        this.stimulate("Users::Wallets::SendReflex#select_send_type", event.target.value)
    }

    setCameraStatus(hasError) {
        const errorMessage = document.getElementById('video-qr-message-error')
        const camContainer = document.getElementById('video-qr-container')
        errorMessage.classList.toggle('hidden', !hasError)
        camContainer.classList.toggle('hidden', hasError)
    }

    setQrScannerResult(result) {
        if (result.data.indexOf('.') === -1) {
            const cameraQRModal = document.querySelector("#camera-qr-modal");
            const targetInputSelector = cameraQRModal?.attributes["target-input"].value;
            if(!!targetInputSelector){
                const targetInput = document.querySelector(`#${targetInputSelector}`);
                targetInput.value = result.data.split(':').slice(-1)[0]
                if(targetInputSelector === "input-address"){
                    this.addressTarget.dispatchEvent(new CustomEvent("debounced:input"));
                }
            }
            document.getElementById('btn-close-modal').click()
            
        }
    }

    afterModalCameraCaptureQr() {
        setTimeout(() => {
            const videoElem = document.getElementById('video-qr');
            this.qrScanner = new QrScanner(videoElem, result => this.setQrScannerResult(result), {
                highlightScanRegion: true,
                highlightCodeOutline: true,
                returnDetailedScanResult: true
            });
            this.qrScanner.start().catch((e) => {
                // when camera is blocked or there is some error
                this.setCameraStatus(true)
            });
            this.qrScanner.setInversionMode('both');
            QrScanner.hasCamera().then(hasCamera => this.setCameraStatus(!hasCamera));
            const modalCameraQr = document.getElementById('camera-qr-modal');
            self = this;
            this.onElementRemoved(modalCameraQr, function() {
                self.qrScanner.stop();
                self.qrScanner.destroy();
                self.qrScanner = null
            });
        }, 500);
    }

    onElementRemoved(element, callback) {
        new MutationObserver(function(mutations) {
            if(!document.body.contains(element)) {
                callback();
                this.disconnect();
            }
        }).observe(element.parentElement, { childList: true });
    }

    afterReflex(element, reflex) {
        if (reflex === "Users::Wallets::SendReflex#update_coin_summary") {
            this.enableSendButton();
        }
    }
}
