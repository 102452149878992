import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["trade"];

  initialize() {
    this.afterMorphHandler = () => {
      this.initTradeComponents();
    };

    this.showTradeHandler = (e) => {
      this.showTradeChat(e);
    };

    this.initChatsHandler = (eventName) => {
      this.initChats();
      if (eventName == "new-trade") this.roundedActiveTradeAvatar();
    };
  }

  connect() {
    super.connect();
    this.initChats();
    this.sidebar_right = document.querySelector("#right_sidebar");
    this.element.addEventListener("cable-ready:after-morph", this.afterMorphHandler);
    this.element.addEventListener("chat:hidden", this.initChatsHandler("chat:hidden"));
    this.element.addEventListener("new-trade", this.initChatsHandler("new-trade"));
    this.element.addEventListener("show-trade", this.showTradeHandler);

    document.addEventListener("new-trade", (event) => {
      var sound = new Audio(document.getElementById('trade-open-audio').src);
      sound.play();
    })
  }

  disconnect() {
    this.element.removeEventListener(
      "cable-ready:after-morph",
      this.afterMorphHandler
    );
    this.element.removeEventListener("chat:hidden", this.initChatsHandler("chat:hidden"));
    this.element.removeEventListener("new-trade", this.initChatsHandler("new-trade"));
    this.element.removeEventListener("show-trade", this.showTradeHandler);
  }

  initTradeComponents() {
    const tradeChat = document.querySelector("#trade-chat");
    const tradeOperationsWrapper = document.querySelector("#trade-operations");
    const tradeChatInput = document.querySelector("#trade-chat-input");

    if (tradeChat && tradeChatInput && tradeOperationsWrapper) {
      Promise.all([
        import("lib/store"),
        import("lib/components/trade/chat"),
        import("lib/components/trade/chat-input"),
        import("lib/components/trade/operations"),
      ]).then(([store, chatInit, chatInputInit, tradeOperationsInit]) => {
        chatInit.default(store.default, tradeChat);
        chatInputInit.default(store.default, tradeChat);
        tradeOperationsInit.default(store.default, tradeOperationsWrapper);
      });
    }
  }

  initChats() {
    if (!this.hasTradeTarget) return;
    let trades = [
      ...new Map(this.tradeTargets.map((item) => [item["id"], item])).values(),
    ];
    trades.forEach((trade) => {
      Promise.all([
        import("lib/store"),
        import("lib/components/trade/chat"),
      ]).then(([store, chatInit]) => {
        chatInit.default(store.default, trade);
      });
    });
  }

  showTradeChat(e) {
    e.preventDefault();
    let el = document.getElementById(`trade-${e.detail.tradeUUID}`);
    if (!el){
      el = document.createElement('div');
      el.setAttribute('id', `trade-${e.detail.tradeUUID}`);
      el.setAttribute('data-trade-uuid', e.detail.tradeUUID);
      el.setAttribute('data-model', 'Trade');
      let aCol = document.getElementById('active-column');
      aCol.appendChild(el);
      setTimeout(() => {
        this.stimulate("ChatReflex#show", el)
      }, 200);
    }
  }

  roundedActiveTradeAvatar(){
    let imgItem = document.querySelector(".avatar-img.border-3:not(.active-trade)");
    if (imgItem != null) imgItem.classList.add("active-trade");
  }
}
