import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btnLogin", "btnRegister", "wrap"];

  changeSection(sectionName) {
    const btnLoginTarget = this.btnLoginTarget;
    const btnRegisterTarget = this.btnRegisterTarget;
    const buttons = document.querySelector("#payment-link-secure-form-body #buttons.buttonsInLayout");
    const wrap = this.wrapTarget;
    wrap.dataset.section = sectionName;
    if (buttons.classList.contains("hidden")) {
      buttons.classList.remove("hidden");
    } else {
      buttons.classList.add("hidden");
    }

    if (sectionName === "login") {
      btnLoginTarget.setAttribute("open", "");
      btnRegisterTarget.removeAttribute("open");
    } else if (sectionName === "register") {
      btnRegisterTarget.setAttribute("open", "");
      btnLoginTarget.removeAttribute("open");
    }
  }

  connect() {
    const btnLoginTarget = this.btnLoginTarget;
    const btnRegisterTarget = this.btnRegisterTarget;
    btnLoginTarget.addEventListener("click", () => this.changeSection("login"));
    btnRegisterTarget.addEventListener("click", () =>
      this.changeSection("register")
    );
  }

  disconnect() {
    const btnLoginTarget = this.btnLoginTarget;
    const btnRegisterTarget = this.btnRegisterTarget;
    btnLoginTarget.removeEventListener("click", this.changeSection);
    btnRegisterTarget.removeEventListener("click", this.changeSection);
  }
}
