import Vuelidate from 'vuelidate/dist/vuelidate.min.js'
import { required } from 'vuelidate/dist/validators.min.js'
import moment from 'moment'

Vue.use(Vuelidate)

document.addEventListener('turbolinks:load', function(){
const workingHoursForm = document.getElementById('p2p-settings-working-hours-form')

if (workingHoursForm != undefined) {
  const user = JSON.parse(workingHoursForm.dataset.user)

  new Vue({
    el: '#p2p-settings-working-hours-form',
    data: {
      availableAllWeek: user.available_all_week,
      showWorkingHours: {},
      availableHours: [],
      working_days: JSON.parse(user.working_day_attributes).working_days,
      i18n_working_days: user.i18n_working_days
    },
    validations: function() {
      return {
        working_days: this.workingDayValidations()
      }
    },
    mounted: function() {
      this.initAvailableHours()
      this.initSelectHours()
    },
    methods: {
      initAvailableHours(){
        const availableHours = function () {
          let initial_hour = moment().startOf('date')
          let hour = moment().startOf('date')
          let hours = []

          do {
            hours.push(hour.format('hh:mm A'))
            hour.add(15, 'minutes')
          } while (initial_hour.format('LT') != hour.format('LT'))

          hour.subtract(1, 'minute')
          hours.push(hour.format('hh:mm A'))

          return hours
        }

        this.availableHours = availableHours()
      },
      initSelectHours(){
        for (var i = 0; i < this.working_days.length; i++) {
          let working_day = this.working_days[i]
          let weekday = working_day.weekday
          for (var j = 0; j < working_day.working_hours_attributes.length; j++) {
            this.updateHourSelect(weekday, j)
          }
        }
      },
      renderWorkingHours(id){
        this.$set(this.showWorkingHours, id, !this.showWorkingHours[id])
      },
      addWorkingHour(working_day_index){
        var working_hour_attributes = { id: '', initial_hour: '', end_hour: '', _destroy: false }
        var working_day = this.working_days[working_day_index]
        var working_hours  = working_day.working_hours_attributes
        var weekday = working_day.weekday

        if (this.limitWorkingHours(working_hours)) {
          working_hours.push(working_hour_attributes)
          this.updateHourSelect(weekday, working_hours.length-1)
        }
      },
      limitWorkingHours(working_hours){
        return working_hours.filter(wh => !wh._destroy).length < 3
      },
      removeWorkingHour(working_day_index, working_hour_index){
          this.working_days[working_day_index].working_hours_attributes[working_hour_index]._destroy = true
      },
      updateHourSelect(weekday, wh_index){
        Vue.nextTick(function () {
          $(document.getElementById(`initial_hour_${weekday}_${wh_index}`)).dropdown()
          $(document.getElementById(`end_hour_${weekday}_${wh_index}`)).dropdown()
        })
      },
      isUniqueHourRange(working_hour, working_hours_attributes){
        let correctRange = true
        let working_hours = working_hours_attributes.filter(wh => !wh._destroy)
        if (working_hours.length > 1 && this.presentWorkingHour(working_hour)) {
          let index = working_hours.indexOf(working_hour)
          let initial_hour = this.hourToDateTime(working_hour.initial_hour)
          let end_hour = this.hourToDateTime(working_hour.end_hour)

          for (var i = 0; i < working_hours.length && correctRange; i++) {
            if (index != i && this.presentWorkingHour(working_hours[i])) {
              let ih = this.hourToDateTime(working_hours[i].initial_hour)
              let eh = this.hourToDateTime(working_hours[i].end_hour)

              correctRange =
              !initial_hour.isBetween(ih, eh, null, '[]') &&
              !end_hour.isBetween(ih, eh, null, '[]') &&
              !ih.isBetween(initial_hour, end_hour, null, '[]') &&
              !eh.isBetween(initial_hour, end_hour, null, '[]')
            }
          }
        }
        return correctRange
      },
      hourToDateTime(hour){
        let date = moment().format("L")
        return moment(`${date} ${hour}`)
      },
      removeBlankWorkingHours(){
        this.working_days.forEach((working_day) => { working_day.working_hours_attributes = this.filterWorkingHours(working_day) })
      },
      filterWorkingHours(working_day){
        var working_hours = working_day.working_hours_attributes
        return working_day.all_day || this.availableAllWeek || !working_day.active ? [] : working_hours.filter(wh => (wh.initial_hour != '' && wh.end_hour != ''))
      },
      getWorkingDay(working_hour){
        return this.working_days.find(wd => wd.working_hours_attributes.indexOf(working_hour) != -1)
      },
      presentWorkingHour(working_hour){
        return working_hour.end_hour != '' && working_hour.initial_hour != ''
      },
      evaluateWorkingHourValidation(working_hour, working_day){
        return !working_hour._destroy && this.presentWorkingHour(working_hour) && !working_day.all_day && working_day.active  && !this.availableAllWeek
      },
      validWorkingHour(working_hour){
        let working_day = this.getWorkingDay(working_hour)
        let initial_hour = this.hourToDateTime(working_hour.initial_hour)
        let end_hour = this.hourToDateTime(working_hour.end_hour)
        let validRange = initial_hour.isBefore(end_hour)
        return this.evaluateWorkingHourValidation(working_hour, working_day) ? validRange : true
      },
      workingDayValidations(){
        return {
            required,
            $each: {
              working_hours_attributes: {
                $each: {
                  initial_hour: {
                    isBefore: (value, working_hours_attribute) => {
                      return this.validWorkingHour(working_hours_attribute)
                    }
                  },
                  end_hour: {
                    isAfter: (value, working_hours_attribute) => {
                      return this.validWorkingHour(working_hours_attribute)
                    }
                  },
                  isCorrectRange: (value, working_hours_attributes) => {
                    let working_day = this.working_days.find(wd => wd.working_hours_attributes === working_hours_attributes)
                    let all_day = working_day != null ? working_day.all_day : false
                    let active = working_day != null ? working_day.active : false
                    return !all_day  && active && !this.availableAllWeek && !value._destroy? this.isUniqueHourRange(value, working_hours_attributes) : true
                  }
                }
              }
            }
        }
      }
    },
    computed: {
      workingDaysAttributes: function(){
        return JSON.stringify(this.working_days)
      },
    },
    watch: {
      availableAllWeek(newV, oldV){
        if(newV != true && newV != false) this.availableAllWeek = newV == "true"
      }
    }
  })
}
})
