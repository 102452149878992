import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["sendGiftEmail", "sendGiftButton"];

    connect() {
        super.connect();
        const sendGiftEmailInput = document.getElementById("send-gift-email");
        const sendGiftButton = document.getElementById("send-gift-button");
        console.log('sendGiftEmailInput', sendGiftEmailInput);
        sendGiftEmailInput.addEventListener("input", function() {
            sendGiftButton.disabled = !this.value;
        });
    }

    sendGift() {
        const inputElement = document.querySelector('#send-gift-email');

        this.stimulate('SidebarReflex#send_gift', inputElement.value);
        setTimeout(() => {
            const inputElement = document.getElementById("send-gift-email");
            inputElement.value = inputElement.dataset.email_sent;

            setTimeout(() => {
                inputElement.value = ''; // Reset the value
                inputElement.placeholder = inputElement.dataset.default_placeholder;
            }, 5000);
        }, 500);
    }
}
