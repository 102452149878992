import ApplicationController from ".././application_controller";
import StimulusReflex from "stimulus_reflex";

export default class extends ApplicationController {
  static targets = [
    "form",
    "field",
    "buttonSubmit",
    "loaderButton",
    "textButton",
    "textError",
  ];

  static values = {
    minlength: Number,
    otp: String,
  };

  initialize() {
    this.onOtpValidationFailed = () => {
      console.log("Fallo");
      this.hideLoading();
      this.showError();
    };
    this.onOtpValidationSuccessful = () => {
      window.location.reload();
    };
  }

  connect() {
    this.formTarget.addEventListener(
      "otpValidationFailed",
      this.onOtpValidationFailed
    );

    this.formTarget.addEventListener(
      "otpValidationSuccessful",
      this.onOtpValidationSuccessful
    );

    StimulusReflex.register(this);
  }

  disconnect() {
    this.formTarget.removeEventListener(
      "otpValidationFailed",
      this.onOtpValidationFailed
    );
    this.formTarget.removeEventListener(
      "otpValidationSuccessful",
      this.onOtpValidationSuccessful
    );
  }

  validateOTP(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showLoading();
    setTimeout(() => {
      this.stimulate("Otp::ValidateReflex#validate", this.otpValue);
    }, 1000);
  }

  validateOTPModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showLoading();
    setTimeout(() => {
      this.stimulate(
        "Users::Settings::SecurityReflex#check_otp_code",
        this.buttonSubmitTarget,
        this.otpValue
      );
    }, 1000);
  }

  otpValueChanged() {
    if (this.otpValue.length === this.minlengthValue) {
      this.buttonSubmitTarget.disabled = false;
      this.buttonSubmitTarget.click();
    }
  }

  handleInputField({ target }) {
    this.hideError();
    const inputs = [...this.fieldTargets];
    const fieldIndex = inputs.findIndex((field) => field === target);
    const characterLength = 6 - fieldIndex;
    const value = target.value.slice(0, characterLength);

    if (value.length <= 1) {
      target.value = value;
      const step = !!value.length ? 1 : -1;
      const focusToIndex = fieldIndex + step;
      this.otpValue = this.otpFromFields;
      if (focusToIndex < 0 || focusToIndex >= inputs.length) return;
      const nextTarget = inputs[focusToIndex];
      nextTarget.disabled = false;
      nextTarget.focus();
    } else if (value.length > 1) {
      const targetsToChange = inputs.slice(
        fieldIndex,
        value.length + fieldIndex
      );
      targetsToChange.forEach((field, index) => {
        field.value = value[index];
        field.disabled = false;
      });
      const focusToIndex = fieldIndex + value.length;
      this.otpValue = this.otpFromFields;
      if (focusToIndex >= inputs.length) {
        target.blur();
        return;
      }
      const nextTarget = inputs[focusToIndex];
      nextTarget.disabled = false;
      nextTarget.focus();
    }
  }

  showError() {
    [...this.fieldTargets].forEach((field, index) => {
      field.classList.add("field_otp_error");
      if (index === this.fieldTargets.length - 1) {
        field.focus();
      }
    });
    this.textErrorTarget.classList.add("flex");
    this.textErrorTarget.classList.remove("hidden");
  }

  hideError() {
    [...this.fieldTargets].forEach((field) =>
      field.classList.remove("field_otp_error")
    );
    this.textErrorTarget.classList.remove("flex");
    this.textErrorTarget.classList.add("hidden");
  }

  showLoading() {
    [...this.fieldTargets].forEach((field) => (field.disabled = true));
    this.buttonSubmitTarget.disabled = true;
    this.loaderButtonTarget.classList.remove("hidden");
    this.textButtonTarget.classList.add("hidden");
  }

  hideLoading() {
    [...this.fieldTargets].forEach((field) => (field.disabled = false));
    this.buttonSubmitTarget.disabled = false;
    this.loaderButtonTarget.classList.add("hidden");
    this.textButtonTarget.classList.remove("hidden");
  }

  get otpFromFields() {
    return [...this.fieldTargets]
      .map((field) => field.value)
      .toString()
      .replaceAll(",", "");
  }
}
