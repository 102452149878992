import axios from 'axios'
import { csrf_token } from './chat-helpers'

const instance = axios.create({
  baseURL: location.origin,
  headers: {
    'Accept': 'application/json',
    'X-CSRF-TOKEN': csrf_token()
  }
})

instance.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if(error.response && error.response.status) {
    if(error.response.status == 401) {
      Turbolinks.visit("/timeout")
    } else if(error.response.status == 403) {
      alert('You are not allowed to perform this operation');
    } else if (error.response.status == 422) {
      if(error.response.data.error && error.response.data.error.message) {
        alert(error.response.data.error.message)
      }
    }
  }
  return Promise.reject(error)
})

export default instance
