import ApplicationController from "../application_controller";

export default class extends ApplicationController {
    connect() {
        super.connect();        

        document.addEventListener("play-deposit-sound", (event) => {
            console.log("document.getElementById('deposit-audio')")
            console.log(document.getElementById('deposit-audio'))
            var sound = new Audio(document.getElementById('deposit-audio').src);
            sound.play();
        })
    }

    pauseSound() {
        var sound = new Audio(document.getElementById('deposit-audio').src);
        sound.pause();
    }

    disconnect() {
        document.removeEventListener("play-deposit-sound", this.pauseSound);
    }
}
