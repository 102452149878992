var ProgressBar = {
  init: function(el, options) {
    this.options = $.extend({}, ProgressBar.defaults, options);
    this.$el = $(el);

    if(this.$el.attr('data-progress')) {
      this.options.progress = parseFloat(this.$el.attr('data-progress'));
    }
    this.$svg = this.$el.find('svg');
    if(!this.$svg.length) {
      this._createSvg();
    }
    this.options.supportSVGFilter = this.supportSVGFilter();
    if(!this.options.supportSVGFilter) {
      this.$container.attr('filter', null);
      this.$container.attr('stroke-width', '1');
    }
    this._draw();
    this.$el.animate({progressBar: this.options.progress}, 1000);
  },

  set: function(progress) {
    this.options.progress = progress;
    this._createSvg();
    this._draw();
  },

  refresh: function() {
    this._draw();
  },

  _createSvg: function() {
    this.$el.html(this.svgTemplate);
    this.$svg = this.$el.find('svg');
    this.$container = this.$svg.find('#container');
    this.$bar = this.$svg.find('#bar');
  },

  _draw: function() {
    var containerOptions = {
      pill: this.$container,
      paddingY: this.options.padding,
      paddingX: this.options.padding };

    var barOptions = {
      pill: this.$bar,
      paddingY: this.options.padding + this.options.innerPadding,
      paddingX: this.options.padding,
      width: this.options.progress };

    this._drawPill(containerOptions);
    this._drawPill(barOptions);
  },

  _drawPill: function(options) {
    var width = this.$svg.width();
    var height = this.$svg.height();
    var template = "M{LTP} {RTP} A1,1 0 1 1 {RBP} L{LBP} A1,1 0 1 1 {LTP} z";
    var topY = options.paddingY;
    var bottomY = height - options.paddingY;
    var leftX = options.paddingX + height/2;
    var barWidth = typeof options.width == "undefined" ?
        width :
        width * options.width;

    barWidth = Math.max(barWidth, height + options.paddingX*2);

    var rightX = barWidth - options.paddingX - height/2;
    var LTP = leftX + "," + topY;
    var RTP = rightX + "," + topY;
    var LBP = leftX + "," + bottomY;
    var RBP = rightX + "," + bottomY;
    var result = template.
      replace(/{LTP}/g, LTP).
      replace(/{RTP}/g, RTP).
      replace(/{LBP}/g, LBP).
      replace(/{RBP}/g, RBP);
    options.pill.attr('d', result);
  }
}

$.fn.extend({
  progressBar: function(options) {
    var originalArguments = arguments;

    this.each(function() {
      var progressBar = $.data(this, 'ProgressBar');
      if(!progressBar) {
        progressBar = Object.create(ProgressBar);
        progressBar.init(this, options);
        $.data(this, 'ProgressBar', progressBar);
        return;
      }

      if(!progressBar[options])
        return;

      progressBar[options](originalArguments[1]);
    });
  }
})

$.cssHooks.progressBar = {
  get: function(el) {
    var progressBar = $.data(el, 'ProgressBar');
    if(!progressBar) return 0;
    return progressBar.options.progress;
  },

  set: function(el, val) {
    val = parseFloat(val);
    var progressBar = $.data(el, 'ProgressBar');
    if(!progressBar) return;
    progressBar.set(val);
  }
}

$.fx.step.progressBar = function(fx) {
  $.cssHooks.progressBar.set(fx.elem, fx.now);
}

ProgressBar.supportSVGFilter = function() {
  return typeof SVGFEColorMatrixElement !== "undefined" && SVGFEColorMatrixElement.SVG_FECOLORMATRIX_TYPE_SATURATE==2;
}

ProgressBar.svgTemplate =
  `<svg>
    <defs>
      <linearGradient id="progressGradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop stop-color="#EBEBEB" offset="0"/>
        <stop stop-color="#FF5722" offset="0.2"/>
        <stop stop-color="#FF9800" offset="0.4"/>
        <stop stop-color="#FDD835" offset="0.6"/>
        <stop stop-color="#4CAF50" offset="0.8"/>
      </linearGradient>

      <filter id="insetShadow" filterunits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
        <feFlood flood-color="#000" flood-opacity="1"></feFlood>
        <feComposite in2="SourceGraphic" operator="xor"></feComposite>
        <feGaussianBlur stdDeviation="0"></feGaussianBlur>
        <feOffset dx="0" dy="0" result="offsetblur"></feOffset>
        <feFlood flood-color="#000" flood-opacity="1"></feFlood>
        <feComposite in2="offsetblur" operator="atop"></feComposite>
        <feComposite in2="SourceGraphic" operator="in"></feComposite>
        <feMerge>
          <feMergeNode in="SourceGraphic"></feMergeNode>
          <feMergeNode></feMergeNode>
        </feMerge>
      </filter>
    </defs>

    <path id="container"
      d="M10,0 L190,0 A1,1 0 1 1 190,20 L10,20 A1,1 0 1 1 10,0 z"
      fill="#fff" filter="url(#insetShadow)" stroke-width="0" stroke="#CCC" />
    <path id="bar"
      d="M10,5 L50,5 A1,1 0 1 1 50,15 L10,15 A1,1 0 1 1 10,5 z"
      fill="url(#progressGradient)"/>
  </svg>`;

document.addEventListener('turbolinks:load', () => {
  $('.user-progress').progressBar({padding: 0, innerPadding: 4});
  $(window).resize(function() {
    $('.user-progress').progressBar('refresh');
  });
})
