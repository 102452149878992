import ApplicationController from '.././application_controller'
import { Parser } from "expr-eval";
import priceUpdater from '../../lib/reactivePriceUpdater'
import { getNumericValue, getDataValue, setNewValueToTarget } from '../../lib/flip/helpers.js'

export default class extends ApplicationController {
  static targets = ["currencySelect", "hiddenPriceFormula", "priceFormula", "margin", "coin", "operation", "offerTotalAmount", "offerTotalAmountUserCurrency", "hiddenMarginFixedPrice", "hiddenMinimumAmount", "minAmountInput", "offerAmountReal"]

  initialize () {
    this.reset_position_buttons = () => {
      const positions_section_sidebar = document.querySelector("#positions_section_sidebar")
      const buttons = positions_section_sidebar.querySelectorAll("button li")
      buttons.forEach(function (button) {
        console.log("reseteando")
        button.classList.remove("bg-green-500", "text-white")
      });
    }
  }

  connect(){
    super.connect()
    this.initPriceFormula()
    this.initMargin()
    this.initCurrencySelect()
    // initialize validation on offer form
    const offerForm = document.getElementById("offer-form-reflex")
    if (offerForm) {
      const offerLocation = document.getElementById("offer_location")
      if (offerLocation) {
        setTimeout(() => {
          offerLocation.dispatchEvent(new Event('update'))
        }, 1000);
      }
    }

    this.element.addEventListener('ajax:error', (event) => {
        console.log('error');
    })

    
    if(this.hasMarginTarget){
      this.marginTarget.addEventListener("debounced:input", this.reset_position_buttons)
    }

    document.addEventListener("reload-page", (event) => {
      window.location.reload()
    })
  }

  desconnect(){
    if(this.hasMarginTarget){
      this.marginTarget.removeEventListener("debounced:input", this.reset_position_buttons)
    }
  }

  destroyToogle(e){
    e.preventDefault()
    const cancelWarningSection = document.getElementById("cancel-warning-section")
    cancelWarningSection.classList.toggle("hidden")
  }

  initPriceFormula(){
    if(!this.hasPriceFormulaTarget) return;

    this.priceFormulaCleave = new Cleave(this.priceFormulaTarget, { numeral: true, numeralPositiveOnly: true, numeralDecimalScale: 2 });
  }

  initMargin(){
    if(!this.hasMarginTarget) return;

    new Cleave(this.marginTarget, { numeral: true, numeralPositiveOnly: false, numeralDecimalScale: 2, tailPrefix: true, Prefix: '%' })
  }

  initCurrencySelect(){
    if(!this.hasCurrencySelectTarget) return;

    $(this.currencySelectTarget).dropdown();
  }

  updateFields(){
    this.initPriceFormula()
    this.initMargin()
  }

  clickPriceFormula(){
    let elements= document.getElementsByClassName("margin_prices");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("bg-green-500");
      elements[i].classList.remove("text-white");
    }
  }


  selectPositionFix(event){
      setTimeout(() => {
        let offer_margin_big_lbl = document.getElementById("offer_margin_big_lbl").innerText.slice(1, -1);
        offer_margin_big_lbl = offer_margin_big_lbl.replace(/,/g, '.');
        offer_margin_big_lbl = parseFloat(offer_margin_big_lbl);
        let price_formula = document.getElementById("trade-price-formula").innerText;
        price_formula = price_formula.replace(/,/g, '');
        price_formula = parseFloat(price_formula);
        let before_margin = parseFloat(document.getElementById("reactive-margin").innerText);
        let value= price_formula - (((before_margin - offer_margin_big_lbl) / 100) * price_formula);
        value=value.toLocaleString("en-US", {maximumFractionDigits: 2,});
        let element= document.getElementById("offer_price_formula");
        let elementInput= document.getElementById("price_formula");
        element.value = value;
        elementInput.value = value;
        this.stimulateReflex(event, "Users::OffersReflex#update_margin_fixed_price")
        this.stimulateReflex(event, "Users::OffersReflex#update_fixed_price")
      }, 1000);
  }

  updatePriceFormula() {
    if (this.hasHiddenMarginFixedPriceTarget)
      this.stimulateReflex(event, "Users::OffersReflex#update_margin_fixed_price")
      this.stimulateReflex(event, "Users::OffersReflex#update_fixed_price")

    this.hiddenPriceFormulaTarget.value = this.priceFormulaCleave.getRawValue();

    this.updateTotalAmount(event)
  }

  updateCurrency(event){
    this.stimulateReflex(event, "Users::OffersReflex#update_currency")
  }

  updateCoin(event){
    this.stimulateReflex(event, "Users::OffersReflex#update_coin")
  }

  incrementMargin(){
    this.marginTarget.value = (Number(this.marginTarget.value) + 0.01).toFixed(2);
    this.marginTarget.dispatchEvent(new Event("debounced:input"));
  }

  decrementMargin(){
    this.marginTarget.value = (Number(this.marginTarget.value) - 0.01).toFixed(2);
    this.marginTarget.dispatchEvent(new Event("debounced:input"));
  }

  updatePrice(event){
    this.stimulateReflex(event, "Users::OffersReflex#update_price")
  }


  updateTotalAmount(event){
    if (event.detail?.silent || !this.hasOfferTotalAmountUserCurrencyTarget) return;

    let target = event.currentTarget;
    let lblFormula = document.querySelector("#price_conversion p[data-reactive-price-formula-value]");
    let rate = 0;

    if (!lblFormula){
      rate = parseFloat(getNumericValue(document.querySelector("#price_formula")));
    } else {
      let expr = new Parser().parse(lblFormula.attributes["data-reactive-price-formula-value"].value);
      rate = expr.evaluate(priceUpdater.currentVariables);
    }

    if (target == this.offerTotalAmountUserCurrencyTarget) {
      let precision = parseFloat(getDataValue(this.offerTotalAmountTarget, "precision"));
      let newValue = (getNumericValue(target) / rate);
      setNewValueToTarget(this.offerTotalAmountTarget, newValue.toFixed(precision));
      this.offerTotalAmountTarget.dispatchEvent(new CustomEvent("input", { detail: { silent: true }}));
      let total_amount = document.getElementById('offer_amount_real');
      total_amount.value = newValue;
    } else {
      let newValue = (getNumericValue(target) * rate).toFixed(2);
      setNewValueToTarget(this.offerTotalAmountUserCurrencyTarget, newValue);
      const real_amount = document.getElementById('offer_amount_real');
      if (real_amount) real_amount.value = this.offerTotalAmountTarget.value;
    }

    this.updateMinimumValueLabel()
    this.updateMaxLimit()
  }
  updateMaxLimit() {
    const edtMaxLimit = document.getElementById('offer_max_tx_limit')
    const edtTotalAmount = document.getElementById('offer_total_amount_price')
    const maxLimit = parseFloat(getNumericValue(edtMaxLimit)) || 0
    const totalAmount = parseFloat(getNumericValue(edtTotalAmount)) || 0
    if (totalAmount > maxLimit) {
      edtMaxLimit.value = totalAmount
    }
  }

  updateMinimumValueLabel(){
    let current_value = parseFloat(getNumericValue(document.querySelector("#offer_total_amount_price")))

    if (current_value >= this.hiddenMinimumAmountTarget.value){
      document.querySelector("#minimum-value-label").classList.add("hidden")
      if (this.operationTarget.value == 'sell') {
        document.querySelector("#available_balance-label").classList.remove("hidden")
      }
    } else {
      document.querySelector("#minimum-value-label").classList.remove("hidden")
      document.querySelector("#available_balance-label").classList.add("hidden")
    }
  }

  fixedPrice(event){
    this.stimulateReflex(event, "Users::OffersReflex#fixed_price", 50)
  }

  fixedPriceFix(event){
    this.stimulateReflex(event, "Users::OffersReflex#calculate_position_margin_price_fix", 50)
    //this.stimulateReflex(event, "Users::OffersReflex#fixed_price", 50)
  }


  submitOfferMargin(event) {
    event.preventDefault()
    this.updateMarketList(event);
    this.updateSidebarLabels();
    return false;
  }

  updateMarketList(event){
    let cryptoFilterBtn = this.getCryptoFilterBtn();
    cryptoFilterBtn.classList.add("ready-to-reload-market");

    this.stimulateReflex(event, "Users::OffersReflex#update_offer_margin", undefined, false);
  }

  getCryptoFilterBtn(){
    return document.querySelector('input#\\_');
  }

  updateSidebarLabels(){
    let margin_container = document.querySelector("#offer_margin_container #offer_margin_big_lbl");
    if (!margin_container) return

    let new_margin = margin_container.innerText;
    document.querySelector("form #offer_margin_big_lbl").innerText = new_margin;

    let available_label = document.querySelector("#offer-available-in-fiat");
    let available_in_currency = Number(available_label.attributes["data-available"].value);
    let new_rate = Number(document.querySelector("#price_conversion span[data-reactive-price-target='price']").innerText.replaceAll(',',''));

    available_label.innerText = Number((available_in_currency * new_rate).toFixed(2)).toLocaleString();

  }

  stimulateReflex(event, reflexAction, timeout= 100, extra_param = undefined){
    setTimeout(() => {
      if (extra_param == undefined){
        this.stimulate(reflexAction, event.target)
      } else {
        this.stimulate(reflexAction, event.target, extra_param)
      }
    }, timeout)
  }

  afterTogglePaymentMethod(element, reflex){
    let btn_elements = document.getElementsByClassName(element.dataset.selectorClass);
    for (element of btn_elements) {
      if (element.classList.contains("bg-gray-50")){
        element.classList.remove("bg-gray-50", "text-gray-500");
        element.classList.add("bg-green-1300", "text-white");
      } else {
        element.classList.remove("bg-green-1300", "text-white");
        element.classList.add("bg-gray-50", "text-gray-500");
      }
    }
    setTimeout(() => {
      const elements = document.getElementsByClassName("offer_payment_method_ids");
      for (element of elements){
        element.dispatchEvent(new Event('update'))
      }
    }, 1000);
  }

  afterReflex(element, reflex){
    if (reflex === "Users::OffersReflex#update_offer_margin") return window.location.reload();

    this.updateFields()
    const offerForm = document.getElementById("offer-form-reflex")

    if (offerForm) {
      const offerLocation = document.getElementById("offer_location")

      setTimeout(() => {
        this.dispatchEvent(offerLocation, reflex, "Users::OffersReflex#set_offer_location", "update")
      }, 500);

      let eventsList = ['update_margin', 'fixed_price', 'update_margin_fixed_price'];
      if(this.hasOfferTotalAmountTarget && eventsList.some(r => `Users::OffersReflex#${r}` === reflex)) {
        this.offerTotalAmountTarget.dispatchEvent(new Event("input"));
        document.dispatchEvent(new Event("format-inputs-after-reflex"))
      }
    }

    let cryptoFilterBtn = this.getCryptoFilterBtn();
    if (cryptoFilterBtn != undefined && cryptoFilterBtn.classList.contains("ready-to-reload-market")) {
      cryptoFilterBtn.classList.remove("ready-to-reload-market");
      this.dispatchEvent(cryptoFilterBtn, reflex, "OffersReflex#search_form", 'click');
    }
  }

  dispatchEvent(element, reflex, reflexToCompare, event = 'change'){
    if(!element && reflex != reflexToCompare) return;

    element.dispatchEvent(new Event(event))
  }
}
