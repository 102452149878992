import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["slide"];

  connect() {
    this.index = 0;
    this.showSlide();
  }

  next() {
    this.index = (this.index + 1) % this.slideTargets.length;
    this.showSlide();
  }

  showSlide() {
    this.slideTargets.forEach((slide, index) => {
      slide.classList.toggle("hidden", index !== this.index);
    });
  }
}
