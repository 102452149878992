import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = ["form"]

  connect() {
    super.connect();
    this.setButtonState();
    this.addDatasetAttributes();
    window.updateHiddenTrixField = this.updateHiddenTrixField;
    this.performSetButtonState()
  }

  disconnect() {
    document.removeEventListener("set-button-state", this.setBtnHandler);
  }

  beforeValidateFormField(element, event, noop, reflexId) {
    let form = this.hasFormTarget ? this.formTarget : element.form;
    let payload = JSON.stringify(this.fieldsToValidatePresence(form));
    this.element.reflexData[reflexId].dataset.dataset["data-fields-to-validate-presence"] = payload;
  }

  setButtonState() {
    let forms = this.formsToValidate;

    forms.forEach((form) => {
      let button = form.querySelector("[data-validation-submit-button]");
      if (!button) return;

      let fields = form.querySelectorAll("[required]");

      fields.forEach((field) => {
        if (!field.value && field.hasAttribute("data-validation-on")) {
          if (form.dataset.validationWizard) {
            let currentStep = form.dataset.validationWizardCurrentStep;
            let attribute = field.name.match(/\[(.*?)\]/)[1];
            let validateOnly = JSON.parse(
              document.querySelector(`[data-wizard-step="${currentStep}"]`)
                .dataset.validateOnly
            );

            if (!validateOnly.includes(attribute)) return;
          }
          button.disabled = true;
          button.classList.add(
            "btn__success--disabled"           
          );
        }
      });
    });
  }

  addDatasetAttributes() {
    let forms = this.formsToValidate;

    forms.forEach((form) => {
      for (const field in form) {
        if (parseInt(field) || field == "0") {
          let formField = form[field];

          if (formField.hasAttribute("data-validation-on")) {
            let event = formField.dataset.validationOn;
            let reflex = `${event}->Validation#validate_form_field`;
            let currentReflex = formField.dataset.reflex;

            if (currentReflex) reflex = reflex.concat(" ", currentReflex);

            formField.dataset.reflex = reflex;
            formField.dataset.reflexDataset = "combined";
            formField.dataset.reflexPermanent = "true";
          }
        }
      }
    });
  }

  fieldsToValidatePresence(form) {
    if (!form) return [];

    let requiredFields = form.querySelectorAll("[required]");
    let names = {};
    let currentStep = form.dataset.validationWizardCurrentStep;

    if (!document.querySelector(`[data-wizard-step="${currentStep}"]`)){
      requiredFields.forEach((e, i) => {
        if (e.dataset.validationOn) names[i] = e.name;
      });
      return names;
    }

    let validateOnly = JSON.parse(document.querySelector(`[data-wizard-step="${currentStep}"]`).dataset.validateOnly);
    requiredFields.forEach((e, i) => {
      let isInCurrentStep = true;
      if (form.dataset.validationWizard){
        let name_matched = e.name.match(/\[(.*?)\]/);
        let attribute = (name_matched == null) ? e.name : name_matched[1];
        isInCurrentStep = validateOnly.includes(attribute);
      }
      if (e.dataset.validationOn && isInCurrentStep) names[i] = e.name;
    });

    return names;
  }

  loadWizardStep() {
    setTimeout(() => this.setButtonState(), 500);
  }

  updateHiddenTrixField(trixEditor, hiddenField) {
    hiddenField.dataset.validationTrixText = trixEditor.editor
      .getDocument()
      .toString()
      .trim();
    hiddenField.dispatchEvent(new Event("change"));
  }

  initValidationsForm() {
    this.setButtonState();
    this.addDatasetAttributes();
  }

  performSetButtonState() {
    this.setBtnHandler = document.addEventListener("set-button-state", (event) => {
      let forms = this.formsToValidate;
      forms.forEach((form) => {
        let button = form.querySelector("[data-validation-submit-button]");
        if (!button) return;
        button.disabled = false;
        button.classList.remove("btn__success--disabled");
      });
      this.setButtonState()
    });
  }

  get formsToValidate() {
    return document.querySelectorAll("form[data-validation-model]");
  }
}
