import axiosInstance from './axiosInstance.js'
import pusher from 'lib/pusher.js'
import TWEEN from '@tweenjs/tween.js'
import consumer from "../channels/consumer";
import { Parser } from 'expr-eval'

class ReactivePriceUpdater {
  constructor() {
    this.controllers = [];
    this.currentVariables = undefined;

    const updater = this;
    this.subscription = consumer.subscriptions.create(
      "ParserVariablesChannel",
      {
        connected() {
          // console.log("Connected to ParserVariablesChannel")
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(variables) {
          updater.currentVariables = variables;
          updater.controllers.forEach((c) => {
            c.updatePrice(variables)
          });
          updater.runAnimations();
        },
      }
    );
    
    this.subscription.perform("send_variables");
  }

  refreshController(controller) {
    controller.updatePrice(this.currentVariables);
    this.runAnimations();
  }

  addController(controller) {
    this.controllers.push(controller);
    if(this.currentVariables) {
      controller.updatePrice(this.currentVariables);
      this.runAnimations();
    }
  }

  removeController(controller) {
    this.controllers = this.controllers.filter(c => c !== controller)
  }

  runAnimations() {
    function animate() {
      if(TWEEN.update()) {
        requestAnimationFrame(animate)
      }
    }
    animate();
  }
}

const priceUpdater = new ReactivePriceUpdater()
export default priceUpdater
