import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for NavReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["jumperItem"]

  connect(){
    this.shortcutFocusSearchEvent()
    this.searchFieldBlurEvent()
  }

  disconnect(){
    this.shortcutFocusSearchEvent()
    this.searchFieldBlurEvent()
  }

  focusItem(index){
    index = index < 0 ? 0 : index

    this.jumperItemTargets[index].classList.add("bg-gray-200")
  }

  blurItem(index){
    index = index < 0 ? 0 : index

    this.jumperItemTargets[index].classList.remove("bg-gray-200")
  }

  nextItem(){
    if(this.currentIndex == this.maxItems - 1) return;

    this.blurItem(this.currentIndex)
    this.currentIndex++
    this.focusItem(this.currentIndex)
  }

  selectItem(){
    this.jumperItemTargets[this.currentIndex].click()
  }

  previousItem(){
    if(this.currentIndex == -1) return;

    this.blurItem(this.currentIndex)
    this.currentIndex--
    this.focusItem(this.currentIndex)
  }

  navigateThroughtItems(event){
    switch (event.key) {
      case "ArrowUp":
        this.previousItem()
        break;
      case "ArrowDown":
        this.nextItem()
        break;
      case "Enter":
        this.selectItem()
        break;
      case "Escape":
        this.searchField.blur()
        break;
      default:
        // this.searchField.focus()
    }
  }

  shortcutFocusSearch(e) {
    var evtobj = window.event? event : e
    if (evtobj.key == "s" && evtobj.ctrlKey){
      evtobj.preventDefault(); evtobj.stopPropagation();
      this.searchField.focus()
    }
  }

  shortcutFocusSearchEvent(){
    if(!this.searchField) return;
    
    document.addEventListener("keydown", () => { this.shortcutFocusSearch() })
  }

  searchFieldBlurEvent(){
    if(!this.searchField) return;

    this.searchField.addEventListener("blur", () => { this.hiddenJumper() })
  }

  hiddenJumper(){
    this.searchField.value = ""
    setTimeout(() => {
      if(this.jumperItems) this.jumperItems.classList.add("hidden")
    }, 130);
  }

  get jumperItems(){
    return document.getElementById("jumperItems")
  }

  get searchField(){
    return document.getElementById("jumper_search")
  }

  get currentIndex(){
    return parseInt(this.jumperItems.dataset.currentIndex)
  }

  get maxItems(){
    return parseInt(this.jumperItems.dataset.maxItems)
  }

  set currentIndex(value){
    this.jumperItems.dataset.currentIndex = value
  }
}
