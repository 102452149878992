import { Controller } from "@hotwired/stimulus";
import { Chart } from 'chart.js/auto';

export default class extends Controller {
  static charts = {};

  connect() {
    let labels = this.element.dataset.labels.split(';');
    let rates = this.element.dataset.rates.split(';');
    this.loadGraph(document.getElementById(this.element.dataset.coinSymbol).id, labels, rates);
  }

  loadGraph(id, labels, rates) {
    (() => {
      let chart_id = id;

      const LastPosition = rates.length - 1;
      const isCurrencyGoingDown = rates[LastPosition] < rates[LastPosition - 1];
      const yAxe = this.element.dataset.stableCoin === "true" ? { min: 0, max: 2, ticks: { stepSize: 0.1 } } : {};

      if (this.constructor.charts[id]) {
        this.constructor.charts[id].destroy();
      }

      this.constructor.charts[id] = new Chart(document.getElementById(chart_id), {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Cotización de BTC",
              data: rates,
              borderWidth: 2,
              borderColor: isCurrencyGoingDown ? "rgba(232, 145, 153, 1)" : "rgba(122, 191, 46, 1)",
              radius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          tooltips: { enabled: false },
          hover: { mode: null },
          events: ['click'],
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          bezierCurve: false,
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            y: {
              display: false,
              ...yAxe
            },
            x: {
              display: false,
            },
          },
        },
      });
    })();
  }
}
