import ApplicationController from './application_controller'

let buttonCurrentText;

export default class extends ApplicationController {
  static targets = ["sendCodeButton", "checkCodeButton", "inputField"];

  connect () {
    super.connect();
    this._pressEscToCloseModal();
    this._setAccountProtector();
    document.addEventListener("verification_successful", this._verificationSuccessful);
    document.addEventListener("verification_failed", this._verificationFailed);
  }

  beforeSendCode(e) {
    this.inputFieldTarget.disabled = false;
    this.inputFieldTarget.value = "";
    this.inputFieldTarget.dispatchEvent(new Event("debounced:input"));
    buttonCurrentText = this.sendCodeButtonTarget.innerText;
    this.sendCodeButtonTarget.innerText = e.dataset["disableWith"];
  }
  
  sendCodeSuccess() {
    this._startCountdown();
    this._enableCheckCodeButton();
  }

  _verificationSuccessful = e => {
    let formToProtectId = e.detail["formToProtectId"];
    let pathToResume = e.detail["pathToResume"];
    let form = document.getElementById(formToProtectId);

    this.stimulate("AccountProtector#close_account_protector");
    form.action  = pathToResume;
    form.submit();
  }

  _verificationFailed = e => {
    let attemptsAllowed = e.detail["attemptsAllowed"];
    let checkCodeButton = document.querySelector("[data-check-code-button]");
    let codeInput = document.querySelector("[data-code-input]");
    let currentValue = codeInput.value;
    let currentVerification = e.detail["currentVerification"];
    
    // error1 = invalid code, error2 = attempts_exceeded
    if (attemptsAllowed <= 1 && currentVerification != "totp") {
      delete checkCodeButton.dataset.reflex;
      checkCodeButton.classList.add("disabled");
      codeInput.value = "error2";
      codeInput.dispatchEvent(new Event("debounced:input"));
      setTimeout(() => codeInput.disabled = true, 500);
    } else {
      codeInput.value = "error1";
      codeInput.dispatchEvent(new Event("debounced:input"));
    }
    
    checkCodeButton.dataset.attemptsAllowed = attemptsAllowed - 1;
    codeInput.value = currentValue;
  }

  _setAccountProtector() {
    let forms = this.formsToProtect;
    let reflex = "click->AccountProtector#open_account_protector";

    forms.forEach(form => {
      if (!form.id) throw new Error("The form must have an ID");

      this._addDataReflexAttributes(form, reflex);
    })
  }

  _addDataReflexAttributes(form, reflex) {
    let currentAction = form.action;
    form.action = "javascript: void(0)";

    for (const field in form) {
      if (parseInt(field) || field == "0") {
        let formField = form[field];
        if (formField.type == "submit") {
          let currentReflex = formField.dataset.reflex;

          if (currentReflex) reflex = reflex.concat(" ", currentReflex);

          formField.dataset.reflex = reflex;
          formField.dataset.formToProtectId = form.id;
          formField.dataset.pathToResume = currentAction;
        }
      } 
    };
  }

  _startCountdown() {
    if (!this.sendCodeButtonTarget) return;

    let button = this.sendCodeButtonTarget;
    
    delete button.dataset.reflex;
    button.classList.add("disabled");

    this._countdown(button);
  }

  _countdown(button) {
    let waitingTime = this.sendCodeButtonTarget.dataset["waitingTime"];
    let secondsRemaining = waitingTime;
    button.innerText = `${secondsRemaining}`;

    let countdown = setInterval(() => {
      secondsRemaining -= 1;
      button.innerText = `${secondsRemaining}`;
    }, 1000);

    setTimeout(() => {
      clearInterval(countdown);
      button.innerText = buttonCurrentText;
      button.classList.remove("disabled");
      button.dataset.reflex = "click->AccountProtector#send_code";
    }, (waitingTime * 1000));
  }

  _enableCheckCodeButton() {
    let checkCodeButton = this.checkCodeButtonTarget;
    if (checkCodeButton.dataset.attemptsAllowed != 0) return;

    checkCodeButton.classList.remove("disabled");
    checkCodeButton.dataset.reflex = "click->AccountProtector#check_code";
    checkCodeButton.dataset.attemptsAllowed = 3;
  }

  _pressEscToCloseModal() {
    if (!this.accountProtector) return;

    window.addEventListener("keydown", (e) => {
      if (!this.accountProtector) return;

      let key = e.key || e.keyCode;
      if (key == "Escape" || key == "27") this.stimulate("AccountProtector#close_account_protector", e.target);
    });
  }

  get formsToProtect() {
    return document.querySelectorAll("form[data-protect-account]");
  }

  get accountProtector() {
    return document.getElementById("account-protector");
  }
}
