export default Vue.mixin({
  beforeMount: function () {
    const vm = this
    if (this == this.$root && this.$el) {
      document.addEventListener('turbolinks:visit', function teardown() {
        vm.$destroy()
        document.removeEventListener('turbolinks:visit', teardown)
      })
    }
  }
})
