import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "button", "source" ]

    connect() {
        this.buttonData = {}
    }

    copySourceOnly() {
        let source = this.sourceTarget.innerText;
        navigator.clipboard.writeText(source);
    }

    copy() {
        this.setupButtonData();
        this.addCopyStyles();
        document.getSelection().removeAllRanges();
        this.prepareCopyElement();
        document.execCommand("copy");
        this.removeCopyStyles();
        this.removeTempElement();
    }

    copyWithoutComponent() {
        document.getSelection().removeAllRanges();
        this.prepareCopyElement();
        let defaultLabel = this.buttonTarget.getAttribute('data-default-label');
        let activeLabel = this.buttonTarget.getAttribute('data-active-label');
        this.buttonTarget.innerText = activeLabel
        document.execCommand("copy");
        setTimeout(()=>{
            this.buttonTarget.innerText = defaultLabel
            document.getSelection().removeAllRanges();
            this.removeTempElement();
        }, 2000)
    }

    prepareCopyElement() {
        let target = this.sourceTarget;
        let hiddenElement = this.sourceTarget.classList.contains("hidden") || this.sourceTarget.hidden;
        if(hiddenElement)
            target = this.createTempElement();
        const nodeName = target.nodeName;
        if (nodeName === "INPUT" || nodeName === "TEXTAREA"){
            target.select();
        } else {
            let range = document.createRange();
            range.selectNode(target);
            document.getSelection().addRange(range);
        }
    }

    setupButtonData(){
        this.buttonData.buttonClassDefault = this.buttonTarget.dataset["buttonClassDefault"];
        this.buttonData.buttonClassActive = this.buttonTarget.dataset["buttonClassActive"];
        this.buttonData.fontAwesomeIconDefault = this.buttonTarget.dataset["fontAwesomeIconDefault"];
        this.buttonData.fontAwesomeIconActive = this.buttonTarget.dataset["fontAwesomeIconActive"];
        this.buttonData.timeActive = parseInt(this.buttonTarget.dataset["timeActive"]);
        this.buttonData.text = this.buttonTarget.dataset["text"];
    }

    addCopyStyles(){
        let classToRemove = this.buttonData.buttonClassDefault.split(" ");
        let classToAdd = this.buttonData.buttonClassActive.split(" ");
        this.buttonTarget.classList.remove(...classToRemove);
        this.buttonTarget.classList.add(...classToAdd);
        this.buttonTarget.innerHTML = `${this.buttonData.text} <span id="clipboard-icon"><i class="${this.buttonData.fontAwesomeIconActive}"></i></span>`;
    }

    removeCopyStyles(){
        let classToRemove = this.buttonData.buttonClassActive.split(" ");
        let classToAdd = this.buttonData.buttonClassDefault.split(" ");
        setTimeout(()=>{
            this.buttonTarget.classList.remove(...classToRemove);
            this.buttonTarget.classList.add(...classToAdd);
            this.buttonTarget.innerHTML = `${this.buttonData.text} <span id="clipboard-icon"><i class="${this.buttonData.fontAwesomeIconDefault}"></i></span>`;
            document.getSelection().removeAllRanges();
        }, this.buttonData.timeActive)
    }

    createTempElement(){
        let tempInput = document.createElement("input");
        tempInput.id = "temp-input-copy"
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = this.sourceTarget.innerHTML;
        document.body.appendChild(tempInput);
        return tempInput;
    }

    removeTempElement(){
        let element = document.getElementById("temp-input-copy");
        if (element)
            document.body.removeChild(element);
    }
}
