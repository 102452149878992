import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "items",
    "item",
    "hiddenInput",
    "filterText",
    "textToShow",
    "selectButton",
    "modalOverlay"
  ];

  connect() {
    if (this.hasHiddenInputTarget){
      if (this.hiddenInputTarget.value)
        this._selectValue(this.hiddenInputTarget.value);
    }
    this._clickOutside();
  }

  updateSelectValue(e) {
    let value = e.currentTarget.dataset.value;

    this._unselectValue();
    this._selectValue(value);
    this._dispathChangeEvent();
    this._resetValues();
  }

  updateSelectValueAfterLoadingPage(e) {
    let filters = document.getElementsByName("offer-market-filter")
    if (filters.length == 0) return;

    filters.forEach( (filter) => {
      let SelectedValue = filter.attributes['selected_value'].value
      if (SelectedValue != null){
        let SelectedOption = document.querySelector('[name="filter-options"] > div[data-value="'+ SelectedValue +'"]');
        let value = SelectedOption?.dataset.value;
        this._unselectValue();
        this._selectValue(value);
        this._resetValues();
      }

    })
  }

  _selectValue(value) {
    let sectionValue = this.element.getAttribute("data-section");
    let tag = sectionValue ? (sectionValue == 'country-input-component' ? 'li' : '') : '';

    let item = this.element.querySelector(`${tag}[data-value='${value}']`);
    if (item == null) return;
    this._selectValueDetails(item, value);
  }

  _selectValueByDescription(value) {
    let item = this.element.querySelector(`[data-description='${value}']`);
    if (item == null) return;
    this._selectValueDetails(item, value);
  }

  _selectValueDetails(item, value) {
    let details = item.querySelector("#details") ? item.querySelector("#details").innerHTML : item.innerHTML;

    item.classList.add("selected-item");
    this.hiddenInputTarget.value = value;
    this.textToShowTarget.innerHTML = details;

    let itemText = this.textToShowTarget.querySelector(`[name='select-option-item-text']`);
    if (itemText != null) {
      if (itemText.attributes.value != undefined) itemText.innerText = itemText.attributes.value.value;
      itemText.classList.remove('hidden');
    }
  }

  _dispathChangeEvent() {
    this.hiddenInputTarget.dispatchEvent(new Event("change"));
  }

  _unselectValue() {
    let item = this.element.querySelector(".selected-item");
    if (!item) return;

    item.classList.remove("selected-item");
  }

  focusField() {
    if (this.hasModalOverlayTarget){
      this.modalOverlayTarget.hidden = false;
    }
    if (this.itemsTarget.hidden) {
      this.itemsTarget.hidden = false;
      if (this.hasFilterTextTarget) this.filterTextTarget.focus();
    } else {
      this._resetValues();
    }
  }

  filterItems(e) {
    let items = this.itemTargets;
    let filterText = e ? e.target.value : this.filterTextTarget.value;

    if (filterText !== "") {
      this.textToShowTarget.style.display = "none";
    } else {
      this.textToShowTarget.style.display = "";
    }
    let regex = new RegExp(filterText, "i");
    if (items[0].dataset.description) {
      items.forEach(
        (item) => {
          item.hidden = !item.dataset.description.match(regex);
          if (!item.dataset.description.match(regex)){
            item.classList.add("hidden");
          } else {
            item.classList.remove("hidden");
          }
        }
      );
    } else {
      items.forEach((item) => (item.hidden = !item.dataset.value.match(regex)));
    }
  }

  _resetValues() {
    if (!this.hasFilterTextTarget) return;

    this.filterTextTarget.value = "";
    this.filterItems();
    this.itemsTarget.hidden = true;
    if (this.hasModalOverlayTarget){
      this.modalOverlayTarget.hidden = true;
    }
  }

  closeList(e) {
    if (e.keyCode == "27" || e.keyCode == "9") this._resetValues();
  }

  openList(e) {
    if (this.itemsTarget.hidden) {
      this.itemsTarget.hidden = false;
    } else {
      this.itemsTarget.hidden = true;
    }
  }

  _clickOutside() {
    window.addEventListener("click", (e) => {
      if (this.element.contains(e.target)) return;

      this._resetValues();
    });
  }

  get defaultValue() {
    return this.data.get("default-value");
  }

  resetInput(){
    if (!this.hasFilterTextTarget) return;

    this.filterTextTarget.value = "";
    this.filterItems();   
  }
}
