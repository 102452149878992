import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mati"];

  connect() {
    super.connect();
    if (this.hasMatiTarget) {
      this.loadMatiSDK();
      this.matiTarget.addEventListener("metamap:userFinishedSdk", this.goToNextStep);
    }
  }

  disconnect() {
    if (this.hasMatiTarget)
      this.matiTarget.removeEventListener("metamap:userFinishedSdk", this.goToNextStep);
  }

  openMatiSDK() {
    if (!this.hasMatiTarget) return;
    this.matiTarget.click();
  }

  goToNextStep() {
    const nextBtn = document.querySelector("#next_btn");
    if (!nextBtn) return;
    nextBtn.click();
  }

  loadMatiSDK() {
    if (document.getElementById("mati-sdk")) return;
    let scriptTag = document.createElement("script");
    scriptTag.src = "https://web-button.getmati.com/button.js";
    scriptTag.id = "mati-sdk";
    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  }
}
