import ApplicationController from "../application_controller";
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
    retryStimulateTimeout = null;
    connect() {
        super.connect();

        const retryStimulation = () => {
            if (this.isActionCableConnectionOpen()) {
                this.stimulate("Users::Settings::P2P::PaymentMethodsReflex#select_country", this.element.dataset.country);
                clearTimeout(this.retryStimulateTimeout);
                this.retryStimulateTimeout = undefined;
            } else {
                StimulusReflex.register(this)
                this.retryStimulateTimeout = setTimeout(retryStimulation, 1000);
            }
        };
        retryStimulation();

    }
    
    disconnect() {
        if (this.retryStimulateTimeout) {
            clearTimeout(this.retryStimulateTimeout);
        }
    }
}