import ApplicationController from "./application_controller";
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {

    connect(){
        StimulusReflex.register(this);
        this.updateActivitiesSidebar();
    }

    updateActivitiesSidebar(){
        setInterval(() => {
            let compare_date = new Date( new Date(Date.now()).setSeconds(0) );
            document.querySelectorAll("#active-operations > div").forEach(activity => {
                let time_to_remove = activity.getAttribute('data-time-to-remove');

                if (time_to_remove != '' && time_to_remove != null && time_to_remove != NaN) {
                    let activity_date = new Date(Date.parse(time_to_remove));

                    let difference_minutes = compare_date.getMinutes()-activity_date.getMinutes();
                    if(compare_date.getMinutes()=== 0 &&activity_date.getMinutes()===59){
                        difference_minutes=3;
                    }else if(activity_date.getMinutes()===59&&compare_date.getMinutes()>=1&&compare_date.getMinutes()<59){
                        activity.remove();
                    }else if(activity_date.getMinutes()===58&&compare_date.getMinutes()>=0&&compare_date.getMinutes()<58){
                        activity.remove();
                    }else if(difference_minutes >= 2){
                        activity.remove();
                    }

                }
            });
        }, 60 * 1000);
    }

}