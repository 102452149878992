import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["notification"]
  static values = {
    tradeUuid: String,
    notificationId: String
  }

  initialize () {
    this.notificationAnimate = (e) => {
      this.animateBadge(e)
    }
  }

  connect() {
    super.connect();
    document.addEventListener("notification-animate", this.notificationAnimate);
  }

  disconnect() {
    document.removeEventListener("notification-animate", this.notificationAnimate);
  }

  ignoreAll() {
    event.preventDefault();
    this.stimulate("Notifications#clear_all");
  }

  seeNotification() {
    event.preventDefault();
    const {
      dataset: { notificationId },
      href,
    } = this.element;
    this.stimulate("Notifications#mark_as_read", notificationId);
    window.location.href = href;
  }

  ignoreNotification() {
    event.preventDefault();
    const {
      dataset: { notificationId },
      href,
    } = this.element;
    this.stimulate("Notifications#clear", notificationId);
  }

  async closeTradesColumn(){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.stimulate("ChatReflex#hidden", this.notificationTarget);
        resolve(true);
      }, 100);
    })
  }

  async showChatTrade(){
    if (this.hasNotificationTarget){
      this.stimulate("Notifications#mark_as_read", this.notificationIdValue);
      await this.closeTradesColumn();
      setTimeout(() => {
        this.stimulate("ChatReflex#show", this.notificationTarget)
      }, 200);
    }
  }

  async goToPath(){
    if (this.hasNotificationTarget){
      this.stimulate("Notifications#mark_as_read", this.notificationIdValue);
      window.location.href = this.notificationTarget.dataset.path;
    }
  }

  animateBadge(e){
    let el = document.getElementById('notification-bell');
    let onAnimationEnd = () => {
      el.style.animation = '';
      el.removeEventListener('animationend', onAnimationEnd);
    }
    el.addEventListener('animationend', onAnimationEnd);
    el.style.animation = 'notification-pulse 2s 3';
  }
}
