import ApplicationController from "../application_controller";

export default class extends ApplicationController {
    static targets = ["modalBody"];

    connect() {
        super.connect();
        this._clickOutsideToCloseModal();
        this._pressEscToCloseModal();
    }

    disconnect() {
        document.removeEventListener("keydown", this.escHandler);
    }

    closeModal() {
        this.element.remove()
        if(this.element.dataset.return == "true") {
            window.history.back();   
        }        
    }

    _clickOutsideToCloseModal() {
        const element = this.element;
        element.addEventListener("click", (e) => {
            let modalBody = this.modalBodyTarget;
            if (modalBody.contains(e.target))
                return;
            element.remove();
        });
    }

    _pressEscToCloseModal() {
        const element = this.element;
        this.escHandler = document.addEventListener("keydown", (event) => {
            if (event.keyCode == 27){
                element.remove();
            }
        });
    }
}
