import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btnBuy", "btnSell", "instructiveWrap"];

  changeSection(sectionName) {
    const btnBuyTarget = this.btnBuyTarget;
    const btnSellTarget = this.btnSellTarget;
    const instructiveWrapTarget = this.instructiveWrapTarget;
    instructiveWrapTarget.dataset.instructive = sectionName;
    if (sectionName === "buy") {
      btnBuyTarget.setAttribute("open", "");
      btnSellTarget.removeAttribute("open");
    } else if (sectionName === "sell") {
      btnSellTarget.setAttribute("open", "");
      btnBuyTarget.removeAttribute("open");
    }
  }

  connect() {
    const btnBuyTarget = this.btnBuyTarget;
    const btnSellTarget = this.btnSellTarget;
    btnBuyTarget.addEventListener("click", ()=>this.changeSection("buy"));
    btnSellTarget.addEventListener("click", ()=>this.changeSection("sell"));
  }

  disconnect() {
    const btnBuyTarget = this.btnBuyTarget;
    const btnSellTarget = this.btnSellTarget;
    btnBuyTarget.removeEventListener("click", this.changeSection);
    btnSellTarget.removeEventListener("click", this.changeSection);
  }
}
