import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"]

  connect() {
    super.connect();
  }

  handleChange(event) {
    let forms = document.querySelectorAll("[data-validation-form]");
    let button = document.querySelector("[data-submit-button]");

    forms.forEach((form) => {
      let canSave = true
      let fields = form.querySelectorAll("[required]");
      fields.forEach((field) => {
        canSave = canSave && (field.value.trim() !== '')
      })

      button.disabled = !canSave;
      button.classList.remove("hover:bg-green-600", "opacity-50", "cursor-not-allowed", "hover:bg-green-1200");
      if (canSave) {
        button.classList.add("hover:bg-green-1200");
      }
      else {
        button.classList.add("hover:bg-green-600", "opacity-50", "cursor-not-allowed");
      }
    })
  }
}
