import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input", "up", "down"];

  initialize() {
    this.step = 1;
    if (this.hasInputTarget) {
      this.step = this.inputTarget.dataset.step;
      this.min = this.inputTarget.min;
      this.max = this.inputTarget.max;
    }
    this.increaseNumber = () => {
      let newValue = Number(this.step) + Number(this.inputTarget.value);
      if (!!this.max && newValue >= this.max)
        return (this.inputTarget.value = this.max);
      this.inputTarget.value = newValue;
    };

    this.decrementNumber = () => {
      let newValue = Number(this.inputTarget.value) - Number(this.step);
      if (!!this.min && newValue <= this.min)
        return (this.inputTarget.value = this.min);
      this.inputTarget.value = newValue;
    };
  }

  connect() {
    if (this.hasUpTarget) {
      this.upTarget.addEventListener("click", this.increaseNumber);
    }

    if (this.hasDownTarget) {
      this.downTarget.addEventListener("click", this.decrementNumber);
    }
  }

  disconnect() {
    if (this.hasUpTarget) {
      this.upTarget.removeEventListener("click", this.increaseNumber);
    }

    if (this.hasDownTarget) {
      this.downTarget.removeEventListener("click", this.decrementNumber);
    }
  }
}
