import consumer from "../../channels/consumer";
import {from} from "rxjs";
import {map} from "rxjs/operators";
import Decimal from 'decimal.js'

export const rates = {};

export const cryptoToUsd = (cryptoAmount, rate) => {
    return cryptoAmount * rate;
}

export const usdToCrypto = (usdAmount, rate) => {
    let cryptoAmount = 0;
    try {
        cryptoAmount = rate > 0 ? usdAmount / rate : usdAmount;
    } catch (e) {
        console.log(e);
    }
    return cryptoAmount;
}

export const usdToUserCurrency = (usdAmount, rateUsd) => {
    let userCurrencyAmount = 0;
    try {
        userCurrencyAmount = rateUsd > 0 ? usdAmount.div(rateUsd) : usdAmount;
    } catch (e) {
        console.log(e);
    }
    return userCurrencyAmount;
}

export const userCurrencyToUsd = (userCurrencyAmount, rateUsd) => {
    return userCurrencyAmount * rateUsd;
}

export const formatValueCleave = (target, precision) => {
    new Cleave(
        target,
        {
            numeral: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: "none",
            numeralDecimalScale: precision
        }
    )
}

export const getRate = (symbol, side) => {
    let rate = 0;
    try {
        rate = parseFloat(rates[symbol][side])
    } catch (e) {
        console.log(`There is no rate for ${symbol} - ${side}` )
        if (process.env.NODE_ENV != "production") {
            console.log(e);
        }
    }
    return rate;
}


export const convertUsdToUserCurrency = (target, usdValue) => {
    let userCurrency = target.getAttribute('data-user-currency');
    let userCurrencyUsdRate = new Decimal(target.getAttribute('data-user-currency-usd-rate'));
    let amount = userCurrency == "USD" ? usdValue : usdToUserCurrency(usdValue, userCurrencyUsdRate);
    return amount.toFixed(2);
}

export const subscribeBitgoChannel = (controller) => {
    consumer.subscriptions.create("BitGoPrimeQuotesChannel", {
        received(data) {
            const dataRates = Object.entries(data).map(([k,v]) => { return {coin: k, buy_price: v["ask_price"], sell_price: v["bid_price"]}});
            // take data and update rates variable
            try {
                from(dataRates).pipe(
                    map(value =>{
                        rates[value.coin] = {"buy_price": value.buy_price, "sell_price": value.sell_price}
                        return rates;
                    })
                ).subscribe(result => {
                    result;
                })
            } catch (e) {
                console.log("Error updating rates");
                if (process.env.NODE_ENV != "production") {
                    console.log(e);
                }
            }
        }
    });
}

export const setupClickOutside = () => {
    document.addEventListener("click", (e) => {
        let coinsToSelect = document.querySelector("#coins-to-select");
        let coinsToConvert = document.querySelector("#coins-to-convert");

        if (coinsToSelect !== null && e.target != coinsToSelect)
            coinsToSelect.classList.add("hidden");

        if (coinsToConvert !== null && e.target != coinsToConvert)
            coinsToConvert.classList.add("hidden");
    })
}


export const setButtonStyle = (elements, target) => {
    elements.forEach((element)=>{
        if (element == target){
            element.setAttribute("open", true)
        } else {
            element.removeAttribute("open")
        }
    })
}

export const setAmountButtonStyle = (controller, target) => {    
    const elements = [controller.maxBalanceTarget, controller.halfBalanceTarget]

    elements.forEach((element)=>{
        if (element == target){
            element.setAttribute("open", true)
        } else {
            element.removeAttribute("open")
        }
    })
}

export const getDataValue = (elem, attr) => {
    return elem.attributes[`data-${attr}`].value
}

export const getNumericValue = (elem) => {
    return elem.value.replaceAll(',','')
}

export const setNewValueToTarget = (inputTarget, newValue) => {
    if (parseFloat(newValue) == 0 || parseFloat(newValue) == NaN){
        inputTarget.value = ''
    } else {
        inputTarget.value = newValue
    }
}

export const formatInput = (field) => {
    let precision = field.getAttribute('data-precision') || 2;
    let cleave = new Cleave(field, {numeral: true, numeralPositiveOnly: true, numeralDecimalScale: precision, delimiter: ','});
    cleave.destroy();
}