import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [
    "passwordInput",
    "confirmPasswordInput",
    "errorLabel",
    "confirmErrorLabel"
  ];

  connect() {
  }

  validateLength() {
    const passwordValue = this.passwordInputTarget
    const confirmPasswordValue= this.confirmPasswordInputTarget
    const label = this.errorLabelTarget
    const confirmLabel = this.confirmErrorLabelTarget

    if (passwordValue){
      if (passwordValue.value) {
        if (passwordValue.value.length >= 8) {
          this.hideErrorLabel(label)
        } else {
          this.showErrorLabel(label)
        }
      }
    }

    if (confirmPasswordValue){
      if (confirmPasswordValue.value) {
        if (confirmPasswordValue.value.length >= 8) {
          this.hideErrorLabel(confirmLabel)
        } else {
          this.showErrorLabel(confirmLabel)
        }
      }
    }
  }

  showErrorLabel(label) {
    if (label) {
      label.classList.remove("hidden");
    }
  }

  hideErrorLabel(label) {
    if (label) {
      label.classList.add("hidden");
    }
  }
}
