import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textarea"];

  initialize() {
    this.preventEnter = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
  }

  connect() {
    if (this.hasTextareaTarget) {
      this.textareaTarget.addEventListener("keypress", this.preventEnter);
    }
  }
  disconnect() {
    if (this.hasTextareaTarget) {
      this.textareaTarget.removeEventListener("keypress", this.preventEnter);
    }
  }

  lengthControl() {
    var bioTextarea = document.getElementById('bioTextarea');

    bioTextarea.addEventListener('input', function() {
        var lines = this.value.split(/\r\n|\r|\n/);
        var originalLineCount = lines.length;
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].length > 30) {
                var overflow = lines[i].substring(30);
                lines[i] = lines[i].substring(0, 30);
                lines.splice(i + 1, 0, overflow);
                i++;
            }
        }
        this.value = lines.slice(0, 2).join('\n');
    });
  }
}
