import ApplicationController from './application_controller';
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ["dropdown", "input", "option", "currentOption", "filter", "filterText"];

  initialize () {
    this.filteredOptions = [];
  }

  connect() {
    this.mouseDownEventWithBinding = this.mouseDownEvent.bind(this);
    document.addEventListener("mousedown", this.mouseDownEventWithBinding);
    StimulusReflex.register(this);
    this._clickOutside();
  }

  disconnect() {
    document.removeEventListener("mousedown", this.mouseDownEventWithBinding);
  }

  openDropdown() {
    this.dropdownTarget.classList.remove("hidden");
    if (this.hasFilterTarget) this.filterTarget.focus();    
    if (this.hasFilterTextTarget) this.filterTextTarget.focus();
  }

  closeDropdown() {
    this.dropdownTarget.classList.add("hidden");
    this.restoreInitialOptions(true)
  }

  toggleDropdown() {
    if (this.dropdownTarget.classList.contains("hidden")) this.openDropdown();
    else this.closeDropdown();
  }

  mouseDownEvent(e) {
    if (!this.element.contains(e.target)) this.closeDropdown();
  }

  keypressInSelectContainer(e) {
    if (!this.dropdownTarget.classList.contains("hidden")) {
      switch (e.keyCode) {
        case 27: // Escape
          this.closeDropdown()
        break;
        case 38: // ArrowUp
        case 40: // ArrowDown
          let optionToSelect;
          let options = this.filteredOptions.length >= 1 ? this.filteredOptions : this.optionTargets;

          options.map((option, index) => {
            if (option.hasAttribute("open")) {
              if (e.keyCode == 38) optionToSelect = options[index - 1] ? options[index - 1] : options[options.length - 1];
              if (e.keyCode == 40) optionToSelect = options[index + 1] ? options[index + 1] : options[0];
            }
          })
          if (!optionToSelect) optionToSelect = options[0];
          this.changeOption('', optionToSelect);
        break;
      }
    }
  }

  keypressInSelectInput(e) {
    switch (e.keyCode) {
      case 13: // Enter
      case 32: //Space
        this.toggleDropdown()
      break;
    }
  }

  changeOption(e, optionToSelect) {
    let section = e.currentTarget.getAttribute("data-section");

    if (!optionToSelect) optionToSelect = e.currentTarget;
    this.selectOption('', optionToSelect);

    if (section && section == 'offer_location'){
      this.inputTarget.value = ''
    }

    if (optionToSelect.dataset.value != this.inputTarget.value) {
      if (this.hasCurrentOptionTarget) this.addSelectedOptionToHTML(optionToSelect);
      this.inputTarget.value = optionToSelect.dataset.value;
      this.inputTarget.dispatchEvent(new Event("change"));
      this.resetValues();
    }

    if (e && e.type == "click") this.closeDropdown();

    if (section == 'trade') {
      this.stimulate("OfferSlide#render_start_trade_button", e.currentTarget.getAttribute("data-conditional"));
    }
  }

  selectOption(e, optionToSelect) {
    if (!optionToSelect) optionToSelect = e.currentTarget;

    this.optionTargets.map(option => option.removeAttribute("open"))
    optionToSelect.setAttribute("open", "");
  }

  addSelectedOptionToHTML(selectedOption) {
    const paymentMethodsContainer = document.getElementById("payment-methods-container");
    if (paymentMethodsContainer) {
      paymentMethodsContainer.classList.remove("hidden");
    }
    this.currentOptionTarget.innerHTML = selectedOption.innerHTML;
    this.currentOptionTarget.setAttribute("value", selectedOption.dataset.value);
  }

  filterOptions(e) {
    let filterText = this.filterTextTarget.value;
    this.filteredOptions = [];
      
    if (filterText) {
      let regex = new RegExp(filterText, "i");
      this.optionTargets.map(option => {
        if (option.dataset.text.match(regex)) {
          option.classList.remove("hidden");
          this.filteredOptions.push(option)
        } else {
          option.classList.add("hidden");
        }
      })
    } else {
      this.restoreInitialOptions()
    }
  }

  restoreInitialOptions(selection) {
    if (this.hasFilterTarget) this.filterTarget.value = "";
    this.filteredOptions = [];
    this.optionTargets.map(option => {
      option.classList.remove("hidden");
      if (selection) {
        option.removeAttribute("open")
        if (option.dataset.value == this.inputTarget.value) option.setAttribute("open", "");
      }
    });
  }

  resetValues() {
    if (!this.hasFilterTextTarget) return;

    this.filterTextTarget.value = "";
    this.filterOptions();    
  }

  _clickOutside() {
    window.addEventListener("click", (e) => {
      if (this.element.contains(e.target)) return;

      this.resetValues();
    });
  }
}
