import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
    static targets = [
        "passwordMessage"
    ];

    connect() {
        super.connect();
        window.localStorage.setItem("SHOW_P2P_ALERT", "yes");
        window.submitInvisibleRecaptchaForm = this.submitInvisibleRecaptchaForm;
    }

    togglePasswordMessage() {
        if (!this.hasPasswordMessageTarget) return;

        this.passwordMessageTarget.classList.toggle("hidden")
    }

    validateRecaptcha(event) {
        event.preventDefault();
        if (typeof grecaptcha !== "undefined") {
            grecaptcha.execute()
        } else {
            document.getElementById("new_session_form").submit();
        }
    }

    submitInvisibleRecaptchaForm(token) {
        document.getElementById("new_session_form").submit();
    }
}
