import ApplicationController from './application_controller';
import Cleave from 'cleave.js';
import priceUpdater from 'lib/reactivePriceUpdater';
import StimulusReflex from 'stimulus_reflex'
import { Parser } from "expr-eval";
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = [ 'amountFiat', 'amountCrypto', 'paymentMethod', 'offerTimeLimit', 'frmStartTrade',
    'btnStartTrade', 'amountCryptoInputUserCurrency' ];
  static values = {
    min: Number,
    max: Number,
    currency: String,
    formula: String,
    precision: String,
    maskPrecision: String
  }

  connect() {
    StimulusReflex.register(this);
  }

  disconnect() {

  }

  updateTimeLimit() {
    if (this.hasPaymentMethodTarget) {
      this.stimulate("OfferSlide#update_offer_time_limit", this.paymentMethodTarget.value);
    }
  }

  addNewPaymentMethod(e) {
    var inputElement = document.getElementById("trade_amount_crypto");
    var valorInput = inputElement.value;

    this.stimulate("OfferSlide#open_new_payment_method_modal", e.currentTarget, valorInput);
  }

  defineMinimum() {
    this.amountCryptoInputUserCurrencyTarget.value = this.minValue;
    this.amountCryptoInputUserCurrencyTarget.dispatchEvent(new Event("debounced:input"));
  }

  defineMaximum() {
    this.amountCryptoInputUserCurrencyTarget.value = this.maxValue;
    this.amountCryptoInputUserCurrencyTarget.dispatchEvent(new Event("debounced:input"));
  }

  updateRate() {
    try {
      const parser = new Parser();
      const expr = parser.parse(this.formulaValue);
      const newValue = expr.evaluate(priceUpdater.currentVariables);

      this.rate = newValue;
    } catch (e) {
      if(e.message.includes("parse error") || e.message.includes("EOF")) {
        console.info("Invalid formula");
      }
      else {
        console.log(e.message)
      }
    }
  }

  onSubmitStartTrade(){
    if(this.frmStartTradeTarget && this.btnStartTradeTarget){
      this.btnStartTradeTarget.setAttribute('disabled', '')
      Rails.fire(this.frmStartTradeTarget, 'submit')
    }
  }
}
