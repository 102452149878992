import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = ["timer", "progress"];

  initialize() {
    this.formatDate = (time) => {
      const date = new Date(time);
      let mm = date.getUTCMinutes();
      let ss = date.getSeconds();

      if (mm < 10) {
        mm = "0" + mm;
      }
      if (ss < 10) {
        ss = "0" + ss;
      }
      const format = mm + ":" + ss;
      return format;
    };
  }

  connect() {
    super.connect();

    if (this.hasTimerTarget && this.hasProgressTarget) {
      this.countdown()
    }
  }

  countdown() {
    this.date = new Date().getTime();
    this.time_window = Number(this.timerTarget.dataset.timeWindow);
    this.time_window_ms = this.time_window * 60000;
    this.expiration = this.date + this.time_window_ms;
    this.interval = setInterval(() => {
      const missing_time = this.expiration - new Date().getTime();
      const time_elapsed = this.time_window_ms - missing_time;
      if (missing_time > 0) {
        this.timerTarget.innerHTML = this.formatDate(missing_time);
        this.progressTarget.style.width = `${(time_elapsed * 100) / this.time_window_ms}%`;
      } else {
        this.progressTarget.style.width = "100%";
        clearInterval(this.interval);
        this.interval = undefined;
        this.stimulate("External::EcommerceReflex#set_timeout", this.timerTarget)
      }
    }, 1000);
  }

  afterSetTimeout() {
    this.countdown();
  }

  cancelPayment(event){
    if(event?.target.dataset.message){
      let message = event.target.dataset.message;
      if(window.confirm(message)){
        // TODO: Add reflex that cancels the payment and returns to the checkout or cart of the issuing ecommerce
        console.log("Pago cancelado")
      }
    }
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
