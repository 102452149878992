export const setupSoundNotifications = () => {
  if (!document.audio) {
    document.audio = new Audio(
      'data:audio/mpeg;base64,//OExAAAAAAAAAAAAEluZm8AAAAHAAAABAAAASAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPz8/Pz8/Pz8/Pz8/Pz8/Pz8/Pz8/Pz8/P39/f39/f39/f39/f39/f39/f39/f39/f3+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/AAAAAAAAAAAAAAAAAAAAAAAAAAAAJAa/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//MUxAAAAANIAAAAAExBTUUzLjk2LjFV//MUxAsAAANIAAAAAFVVVVVVVVVVVVVV//MUxBYAAANIAAAAAFVVVVVVVVVVVVVV//MUxCEAAANIAAAAAFVVVVVVVVVVVVVV'
    )
    const unlockAudio = () => {
      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
      document.audio
        .play()
        .then(() => {})
        .catch(() => {})
    }
    document.body.addEventListener('click', unlockAudio)
    document.body.addEventListener('touchstart', unlockAudio)
  }
}

export const playSoundNotification = (src) => {
  const canplaythrough = () => {
    document.audio.removeEventListener('canplaythrough', canplaythrough)
    document.audio.play()
  }
  const ended = () => {
    document.audio.removeEventListener('ended', ended)
  }
  document.audio.addEventListener('canplaythrough', canplaythrough)
  document.audio.addEventListener('ended', ended)
  if (src) document.audio.src = src
  document.audio.play()
}
