import ApplicationController from "../application_controller";
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for NavReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */


export default class extends ApplicationController {

    connect() {
        super.connect();
        console.log("Connected general controller");
        this.screenInterval;
        this.setupScreenDevice();
    }

    setupScreenDevice(){
        this.screenInterval = setInterval(() =>{
            let mainSection = document.getElementById("main-section");
            let width = this.screenWidth();
            if (mainSection.getAttribute("data-current-width") == width)
                return;
            this.stimulate("Markup::GeneralReflex#set_screen_width", mainSection, width)
        }, 1000);
    }

    screenWidth(){
        return window.innerWidth;
    }

    disconnect() {
        clearInterval(this.screenInterval);
    }
}
