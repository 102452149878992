import ApplicationController from "./application_controller";

const TOTAL_TIME = 15;

export default class extends ApplicationController {
  static targets = ["button", "loader", "time"];

  initialize() {
    this.totalTime = TOTAL_TIME;
    this.updateTime = () => {
      this.timeTarget.innerHTML = this.totalTime;
      if (this.totalTime == 0) {
        this.hideLoading();
      } else {
        this.totalTime -= 1;
        setTimeout(this.updateTime, 1000);
      }
    };
  }

  showLoading() {
    this.buttonTarget.disabled = true;
    this.loaderTarget.classList.remove("hidden");
    this.loaderTarget.classList.add("flex");
    this.updateTime();
  }

  hideLoading() {
    this.buttonTarget.disabled = false;
    this.loaderTarget.classList.remove("flex");
    this.loaderTarget.classList.add("hidden");
    this.totalTime = TOTAL_TIME;
  }
}
