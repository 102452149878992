import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();

    document.addEventListener("scroll-to-comments-section", (event) => {
      this.ScrollDownToCommentsSection()
    })

    this.setPercentageSizes();

    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('section') == 'comments') {
      this.ScrollDownToCommentsSection()
    }
  }

  redirectToProfileSections(event) {
    let target = event.target;
    let elementToScroll = document.getElementById("section_tabs_container");
    elementToScroll.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      this.stimulate(
        "Users::Settings::ProfileReflex#change_section",
        target
      );
    }, 500);
  }

  ScrollDownToCommentsSection() {
    let elementToScroll = document.getElementById("section_tabs_container");
    elementToScroll.scrollIntoView({ behavior: "smooth" });
  }

  setPercentageSizes() {
    let items = document.querySelectorAll("[name=progress-bar]")
    items?.forEach(item => {
      item.style.width = item.dataset.percentage
    })
  }
}
