import ApplicationController from "../application_controller";

export default class extends ApplicationController {
 
    initButtonAction() {        
        document.getElementById("confirm-btn")?.classList?.add("pointer-events-none");
        document.getElementById("submit_periodic_withdrawal_form")?.classList?.add("pointer-events-none");
    }

    stepButtonAction() { 
        
    }

}
