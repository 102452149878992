import consumer from "./consumer"

consumer.subscriptions.create("AppearanceChannel", {
  initialized() {
    this.update = this.update.bind(this)
  },

  connected() {
    this.install()
    this.update()
  },

  disconnected() {
    this.uninstall()
  },

  received(data) {
    if (!(data.event === 'appear' || data.event === 'disappear')) return

    document.querySelectorAll(`[id=active-${data.user_uuid}-bg]`).forEach(element => {
      if (data.event === 'appear') {
        element.classList.remove('bg-gray-600')
        element.classList.add('bg-green-600')
      }
      else if (data.event === 'disappear') {
        element.classList.remove('bg-green-600')
        element.classList.add('bg-gray-600')
      }
    })
  },

  rejected() {
    this.uninstall()
  },

  update() {
    if (this.documentIsActive)
      this.appear()
  },

  appear() {
    this.perform("appear")
  },

  install() {
    window.addEventListener("focus", this.update)
    window.addEventListener("blur", this.update)
    document.addEventListener("turbo:load", this.update)
    document.addEventListener("visibilitychange", this.update)
  },

  uninstall() {
    window.removeEventListener("focus", this.update)
    window.removeEventListener("blur", this.update)
    document.removeEventListener("turbo:load", this.update)
    document.removeEventListener("visibilitychange", this.update)
  },

  get documentIsActive() {
    return document.visibilityState === "visible" && document.hasFocus()
  },
})
