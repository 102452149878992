import ApplicationController from "../application_controller";
import {getDataValue, getNumericValue, setNewValueToTarget, formatInput} from "../../lib/flip/helpers";
import {Parser} from "expr-eval";
import priceUpdater from "../../lib/reactivePriceUpdater";

export default class extends ApplicationController {
    static targets = ["CoinInput", "amountCoinInput", "amountFiatInput"]

    connect(){
        super.connect();
        this.setFormatInputs();
        document.addEventListener("format-inputs-after-reflex", (event) => {
            this.setFormatInputs()
        })
    }

    setFormatInputs(){
        if (this.hasAmountCoinInputTarget) {
            formatInput(this.amountCoinInputTarget);
        }
        if (this.hasAmountFiatInputTarget) {
            formatInput(this.amountFiatInputTarget);
        }

        let target = this.amountCoinInputTarget;
        let coinId = getDataValue(target, "coin");
        let section = getDataValue(target, "module-name");
        let coinInputValue = target.value;

        if (section && section == 'send') {
            let validAddresFlag = document.getElementById("input-address-message").dataset.addressFlag

            if (validAddresFlag && validAddresFlag == 'true') {
                this.stimulate("Users::Wallets::SendReflex#check_founds", coinId, coinInputValue, true)
            } else {
                this.stimulate("Users::Wallets::SendReflex#check_founds", coinId, coinInputValue, false)
            }
        }
    }

    loadValue(e) {
        let target = e.currentTarget;
        let rate = getDataValue(target, "rate");
        this.setAmountButtonStyle(target);
        this.setFixedInput(target);

        if (["offer_total_amount", "offer_total_amount_price"].includes(target.id)) {
            rate = this.getRateFromOfferForm();
        }

        if (target == this.amountCoinInputTarget){
            let newValue = (getNumericValue(target) * rate).toFixed(2);
            setNewValueToTarget(this.amountFiatInputTarget, newValue)
        } else {
            let precision = parseFloat(getDataValue(this.amountCoinInputTarget, "precision"));
            let newValue = (getNumericValue(target) / rate).toFixed(precision);
            setNewValueToTarget(this.amountCoinInputTarget, newValue)
            if (target.id == "offer_total_amount") {
                this.amountCoinInputTarget.dispatchEvent(new CustomEvent("input", { detail: { silent: true }}));
            }
        }
        this.setFormatInputs();
        let customEvent = getDataValue(target, "custom-event");
        if (customEvent != null)
            document.dispatchEvent(new Event(customEvent))
    }

    getRateFromOfferForm() {
        let price_formula = document.querySelector("#price_formula");
        let lblFormula = document.querySelector("#price_conversion p[data-reactive-price-formula-value]");

        if (!lblFormula && price_formula){
            return parseFloat(getNumericValue(price_formula));
        } else {
            let expr = new Parser().parse(getDataValue(lblFormula, "reactive-price-formula-value"));
            return expr.evaluate(priceUpdater.currentVariables);
        }
    }

    setFixedInput(target) {
        this.amountCoinInputTarget.setAttribute('data-fixed', "false");
        this.amountFiatInputTarget.setAttribute('data-fixed', "false");
        target.setAttribute('data-fixed', "true");
    }

    setAmountButtonStyle(target) {
        let elements = [document.querySelector("#maxBalance"), document.querySelector("#halfBalance")];
        elements = elements.filter(elem => elem != null);

        elements.forEach((element)=>{
            if (element == target){
                element.setAttribute("open", true)
            } else {
                element.removeAttribute("open")
            }
        })
    }
}
