import axiosInstance from 'lib/axiosInstance'

$(document).on('turbolinks:load', function(){
  var element = document.getElementById('escrow-warning');
  if (element){
    new Vue ({
      el: '#escrow-warning',
      data: {
        escrow_warning_message: true
      },
      methods: {
        updateEscrowWarningAgreed: function(){
          this.escrow_warning_message = false
          axiosInstance.post("/u/escrow_warning_agreed")
        }
      }
    })
  }
})

