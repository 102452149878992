import ApplicationController from './application_controller'
import intlTelInput from 'intl-tel-input'
import utils from 'intl-tel-input/build/js/utils'
import StimulusReflex from "stimulus_reflex";

var intl;

export default class extends ApplicationController {
  static targets = [
    "phoneModal",
    "countdownButton",
    "spinner",
    "spinnerCounter",
    "smsTwilioChannel",
    "callTwilioChannel",
    "smsTwilioChannelLabel",
    "callTwilioChannelLabel",
    "runCountdown",
    "btnSubmit",
    "phoneVerificationCode"
  ];

  connect() {
    StimulusReflex.register(this);
    super.connect();
    this.initIntlTelInput();
  }

  phoneOperations(e) {
    let phoneField = document.getElementById("user_phone_hidden");
    let phoneIsValid = intl.isValidNumber();

    if (!e.target.value) {
      phoneField.value = "";
    } else {
      phoneField.value = intl.getNumber();

      if (phoneIsValid) {
        phoneField.dataset.phoneInvalid = false;
        delete phoneField.dataset.phoneErrorCode;
      } else {
        phoneField.dataset.phoneInvalid = true;
        phoneField.dataset.phoneErrorCode = intl.getValidationError();
      }
    };

    phoneField.dispatchEvent(new Event("change"));
  }

  initIntlTelInput(){
    if(!this.hasPhoneModalTarget) return;

    intl = intlTelInput(this.phoneModalTarget, {
      utilsScript: utils,
      preferredCountries: ["co", "ca", "cl", "ar", "ve", "pe"]
    })
  }

  resendPhoneVerificationCode() {
    this._buttonCountdown();
    this.countdownButtonTarget.setAttribute('data-twilio-channel', this.smsTwilioChannelTarget.checked ? "sms" : "call");
    this.stimulate("VerificationsModalReflex#send_phone_verification_code", this.countdownButtonTarget);
  }

  _buttonCountdown() {
    if (!this.hasCountdownButtonTarget) return;

    let button = this.countdownButtonTarget
    this._disableChannelOptions();
    button.href = "javascript: void(0)"
    delete button.dataset.action
    button.classList.add("disabled");
    this._startCountdown(button, 40);
  }

  _disableButton(button) {
    button.disabled = true
    button.classList.add("hover:bg-green-600", "opacity-50", "cursor-not-allowed")
  }

  _startCountdown(target, waitingTime) {
    let secondsRemaining = waitingTime;
    let spinner = this.spinnerTarget;
    let spinnerCounter = this.spinnerCounterTarget;
    spinnerCounter.innerText = `${secondsRemaining}`;
    spinner.classList.remove("hidden");
    let countdown = setInterval(() => {
      secondsRemaining -= 1;
      spinnerCounter.innerText = `${secondsRemaining}`;
    }, 1000);
    setTimeout(() => {
      clearInterval(countdown);
      target.disabled = false;
      target.classList.remove("disabled");
      target.dataset.action = "click->verifications-modal#resendPhoneVerificationCode"
      spinner.classList.add("hidden");
      this._enableChannelOptions();
    }, (waitingTime * 1000));
  }

  _disableChannelOptions(){
    this.smsTwilioChannelTarget.disabled = true;
    this.smsTwilioChannelTarget.classList.add("cursor-not-allowed");
    this.smsTwilioChannelLabelTarget.classList.add("text-gray-300", "cursor-not-allowed");
    this.callTwilioChannelTarget.disabled = true;
    this.callTwilioChannelTarget.classList.add("cursor-not-allowed");
    this.callTwilioChannelLabelTarget.classList.add("text-gray-300", "cursor-not-allowed");
  }

  _enableChannelOptions() {
    this.smsTwilioChannelTarget.disabled = false;
    this.smsTwilioChannelTarget.classList.remove("cursor-not-allowed");
    this.smsTwilioChannelLabelTarget.classList.remove("text-gray-300", "cursor-not-allowed");
    this.callTwilioChannelTarget.disabled = false;
    this.callTwilioChannelTarget.classList.remove("cursor-not-allowed");
    this.callTwilioChannelLabelTarget.classList.remove("text-gray-300", "cursor-not-allowed");
  }

  afterReflex(element, reflex) {
    if (reflex == "VerificationsModalReflex#increase_step" && this.runCountdownTarget.value == "true"){
      if (this.hasPhoneVerificationCodeTarget) {
        this._buttonCountdown();
        this.runCountdownTarget.value = "false";
      }
    }
  }
}