import consumer from './consumer'
import CableReady from 'cable_ready'

document.addEventListener("turbolinks:load", function() {
  if (currentUserUuid) {
    let channel = consumer.subscriptions.subscriptions.find(suscription => {
      let identifier = JSON.parse(suscription.identifier);
      return identifier.channel === 'TradeAlertsChannel' && identifier.user_uuid === currentUserUuid;
    });
    if (!channel){
      consumer.subscriptions.create({channel: 'TradeAlertsChannel', user_uuid: currentUserUuid}, {
        received (data) {
          if (data.cableReady)
            CableReady.perform(data.operations, {
              emitMissingElementWarnings: false
            })
          }
        })
    }
  }
})
