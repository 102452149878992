import { suppressDeprecationWarnings } from "moment";
import ModalController from "./modal_controller";

export default class extends ModalController {
    connect() {
        super.connect();
    }

    deleteOffer(event) {
        event.target.classList.add("disabled");
        this.stimulate("Users::OffersReflex#delete_offer", event.target)
    }
}
