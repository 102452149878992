import { Controller } from "@hotwired/stimulus";
import { Chart } from 'chart.js/auto';

export default class extends Controller {
  static chart

  connect() {
    let graphData = JSON.parse(this.element.dataset.graphData);
    this.loadGraph(graphData);
  }

  loadGraph(graphData) {
    const ctxBTCVolatilityChart = document.getElementById("btcVolatilityChart");
    
    var times = graphData.map(function(obj) {
      return obj.time_close;
    });

    var rates = graphData.map(function(obj) {
      return obj.rate_close;
    });

    if (this.constructor.chart) {
      this.constructor.chart.destroy();
    }
    this.constructor.chart = new Chart(ctxBTCVolatilityChart, {
      type: "line",
      data: {
        labels: times,
        datasets: [
          {
            label: "Volatilidad del BTC",
            data: rates,
            borderWidth: 2,
            borderColor: "rgba(255, 184, 0, 1)",
            fill: "start",
            backgroundColor: "rgba(255, 184, 0, 0.3)",
            radius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        bezierCurve: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
      },
    });
  }
}
