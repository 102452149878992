import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for AccountReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="AccountReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "AccountReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
  static targets = ["amount", "withdrawalAddress"]

  connect(){
    this.initAmountCleave()
    this.initWithdrawalAddressPasteListener()
    this.initWithdrawalAmountPasteListener()
  }

  initAmountCleave(){
    if(this.hasAmountTarget){
      new Cleave(this.amountTarget, {numeral: true, numeralPositiveOnly: true, numeralThousandsGroupStyle: 'none', numeralDecimalScale: this.data.get('precision')})
    }
  }

  initWithdrawalAddressPasteListener(){
    if(this.hasWithdrawalAddressTarget){
      this.withdrawalAddressTarget.addEventListener("paste", () => {
        setTimeout(() => {
          this.withdrawalAddressTarget.blur()
        }, 10);
      })
    }
  }

  initWithdrawalAmountPasteListener(){
    if(this.hasAmountTarget){
      this.amountTarget.addEventListener("paste", () => {
        setTimeout(() => {
          this.amountTarget.blur()
        }, 10);
      })
    }
  }

  assingAmount(event){
    if(this.hasAmountTarget){
      setTimeout(() => {
        this.amountTarget.value = this.data.get("balance")
        this.amountTarget.dispatchEvent(new Event('change'))
      }, 50);
    }
  }
}
