import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for NavReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["item", "new"];

  connect() {
    super.connect();
    StimulusReflex.register(this);
    this.wallet_list = document.querySelector("#wallet_list");
  }

  closeAllItems() {
    let items = this.wallet_list.querySelectorAll(".items_list");
    items.forEach((item) => {
      item.removeAttribute("open");
    });
  }

  showItem() {
    this.closeAllItems();
    if (this.hasItemTarget) {
      this.itemTarget.setAttribute("open", "");
    }
  }

  hiddenItem() {
    this.closeAllItems();
    if (this.hasItemTarget) {
      this.itemTarget.removeAttribute("open");
    }
  }

  toggleNewForm() {
    this.closeAllItems();
    if (this.hasNewTarget) {
      this.newTarget.toggleAttribute("open");
    }
  }

  closeItem() {
    if (this.hasItemTarget) {
      this.itemTarget.removeAttribute("open");
    }
  }
}
