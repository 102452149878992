import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "div" ]

  connect() {
    this.hide()
  }

  hide() {
    setTimeout(() => {
      this.element.style.display = 'none';
    }, 2000);
  }
}