import ApplicationController from "./application_controller";
import priceUpdater from "../lib/reactivePriceUpdater";
import { Parser } from "expr-eval";
import TWEEN from "@tweenjs/tween.js";
import { of } from "rxjs";

export default class extends ApplicationController {
  static values = { formula: String, precision: Number };

  static targets = ["price", "currency"];

  connect() {
    priceUpdater.addController(this);
    // this.element.style.border = 'solid 3px green';
  }

  disconnect() {
    priceUpdater.removeController(this);
    // this.element.style.border = 'solid 3px red';
  }

  formulaValueChanged() {
    priceUpdater.refreshController(this);
  }

  updatePrice(variables) {
    const controller = this;
    const priceFormula = controller.formulaValue;
    const oldValue = controller.data.get("oldValue") || 0;
    const currency = controller.data.get("currency");
    const precision = controller.precisionValue;
    const headerDepositButton = document.querySelector(
      "#header_deposit_button"
    );
    const headerSendButton = document.querySelector("#header_send_button");

    try {
      const parser = new Parser();
      const expr = parser.parse(priceFormula);
      const newValue = precision
        ? expr.evaluate(variables).toFixed(precision)
        : expr.evaluate(variables);
      let animationState = { currentValue: oldValue };

      new TWEEN.Tween(animationState)
        .to({ currentValue: newValue }, 300)
        .easing(TWEEN.Easing.Quintic.InOut)
        .onUpdate(() => {
          if (!controller.hasCurrencyTarget) {
            if (animationState.currentValue > 0) {
              headerSendButton?.classList.remove("hidden");
              headerDepositButton?.classList.add("hidden");
            } else {
              headerSendButton?.classList.add("hidden");
              headerDepositButton?.classList.remove("hidden");
            }
            controller.priceTarget.innerHTML =
              animationState.currentValue.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              });
          } else {
            controller.priceTarget.innerHTML = `$ ${animationState.currentValue.toLocaleString(
              "en-US"
            )}`;
          }
          controller.data.set("oldValue", animationState.currentValue);
        })
        .start();

      if (controller.hasCurrencyTarget)
        controller.currencyTarget.innerHTML = currency;
    } catch (e) {
      // Invalid formula
      controller.priceTarget.innerHTML = "-";
      if (controller.hasCurrencyTarget)
        controller.currencyTarget.innerHTML = "";
    }
  }
}
