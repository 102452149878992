import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "privacyPolicy",
    "termsOfService",
    "toogleTranslate",
    "updateEmailForm",
    "passwordMessage",
    "localeStatusIcon",
    "timeZoneStatusIcon",
    "currencyStatusIcon",
    "languageMenu",
  ];

  connect() {
    super.connect();
    window.submitInvisibleRecaptchaForm = this.submitInvisibleRecaptchaForm;
    window.porUploaded = this.porUploaded;
    window.avatarUploaded = this.avatarUploaded;
    window.incorporationCertificateUploaded = this.incorporationCertificateUploaded;
    window.taxIdentificationUploaded = this.taxIdentificationUploaded;
    window.recentTaxFormUploaded = this.recentTaxFormUploaded;
    window.frontIdCardUploaded = this.frontIdCardUploaded;
    window.backIdCardUploaded = this.backIdCardUploaded

  }

  updateCharCount(e) {
    let charCounter = document.getElementById("bio-char-counter");
    let charCount = e.target.value.length;

    charCounter.innerText = charCount;
    (charCount > 160) ? charCounter.classList.add("text-red-300") : charCounter.classList.remove("text-red-300")
  }

  toogleLanguageMenu() {
    let menu = this.languageMenuTarget;
    if (!menu) return;

    if (menu.hidden) {
      menu.hidden = false;
      menu.classList.remove('opacity-0', 'scale-95', 'ease-out', 'duration-100');
      menu.classList.add('opacity-100', 'scale-100', 'ease-in', 'duration-75');
    } else {
      menu.classList.add('opacity-0', 'scale-95', 'ease-out', 'duration-100');
      menu.classList.remove('opacity-100', 'scale-100', 'ease-in', 'duration-75');
      menu.hidden = true;
    };
  }

  togglePasswordMessage() {
    if (!this.hasPasswordMessageTarget) return;

    this.passwordMessageTarget.classList.toggle("hidden")
  }

  afterUpdateEmailAndResendConfirmation(element) {
    this.updateEmailFormTarget.reset()
  }

  beforeUpdateUserLanguage() {
    this.showUpdatingInProcessIcon(this.localeStatusIconTarget);
  }

  updateUserLanguageSuccess() {
    this.showUpdatingCompletedIcon(this.localeStatusIconTarget);

    setTimeout(() => Turbolinks.visit(location.toString()), 500);
  }

  beforeUpdateUserTimeZone() {
    this.showUpdatingInProcessIcon(this.timeZoneStatusIconTarget);
  }

  updateUserTimeZoneSuccess() {
    this.showUpdatingCompletedIcon(this.timeZoneStatusIconTarget);

    setTimeout(() => this.timeZoneStatusIconTarget.innerHTML = "", 1500);
  }

  beforeUpdateUserCurrency() {
    this.showUpdatingInProcessIcon(this.currencyStatusIconTarget);
  }

  updateUserCurrencySuccess() {
    this.showUpdatingCompletedIcon(this.currencyStatusIconTarget);

    setTimeout(() => this.currencyStatusIconTarget.innerHTML = "", 1500);
  }

  showUpdatingInProcessIcon(target) {
    target.innerHTML = "<i class='fad fa-spinner-third fa-spin' style='color:#2D83AE;'></i>";
  }

  showUpdatingCompletedIcon(target) {
    target.innerHTML = "<i class='fad fa-check-circle' style='color:#7ABF2E;'></i>";
  }

  updateUsernameSuccess() {
    Turbolinks.visit("/u/settings/profile?verification_completed=username");
  }

  updateUserAvatarSuccess() {
    Turbolinks.visit("/u/settings/profile?verification_completed=avatar");
  }

  validateRecaptcha(event) {
    event.preventDefault();
    if (typeof grecaptcha !== "undefined") {
      grecaptcha.execute()
    } else {
      document.getElementById("registration_form").submit();
    }
  }

  submitInvisibleRecaptchaForm(token) {
    document.getElementById("registration_form").submit();
  }

  porUploaded(data) {
    let target = document.getElementById("proof_of_residence")

    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event("por_uploaded"))
  }

  incorporationCertificateUploaded(data) {
    let target = document.getElementById('incorporation_certificate')

    document.getElementById("incorporation_certificate_label").textContent = data.filesUploaded[0]["filename"]
    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event('incorporation_certificate_uploaded'))
  }

  taxIdentificationUploaded(data) {
    let target = document.getElementById("tax_identification")

    document.getElementById("tax_identification_label").textContent = data.filesUploaded[0]["filename"]
    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event('tax_identification_uploaded'))
  }

  recentTaxFormUploaded(data) {
    let target = document.getElementById("recent_tax_form")

    document.getElementById("recent_tax_form_label").textContent = data.filesUploaded[0]["filename"]
    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event('recent_tax_form_uploaded'))
  }

  frontIdCardUploaded(data) {
    let target = document.getElementById("front_id_card")

    document.getElementById("front_id_card_label").textContent = data.filesUploaded[0]["filename"]
    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event('front_id_card_uploaded'))
  }

  backIdCardUploaded(data) {
    let target = document.getElementById("back_id_card")

    document.getElementById("back_id_card_label").textContent = data.filesUploaded[0]["filename"]
    target.value = JSON.stringify(data.filesUploaded[0])
    target.dispatchEvent(new Event('back_id_card_uploaded'))
  }

  avatarUploaded(data) {
    let target = document.getElementById("filestack-avatar")
    let payload = JSON.stringify(data.filesUploaded[0])

    target.dataset.avatarPayload = payload
    target.dispatchEvent(new Event("avatar_uploaded"))
  }

  updateUserAvatar() {
    document.getElementById("filestack-avatar").click()
  }

  toggleCheckboxPolicies (event) {
    this.privacyPolicyTarget.value = event.target.checked;
    this.termsOfServiceTarget.value = event.target.checked;

    var event = new Event('change');
    this.privacyPolicyTarget.dispatchEvent(event);
  }
}
