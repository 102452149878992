import ApplicationController from '.././application_controller'

export default class extends ApplicationController {
  static targets = ['btnStandard', 'btnSecure']

  connect() {
    super.connect()
  }

  setLinkType(e){
    const btnClicked = e.target;
    const btnCopy = document.getElementById("btn-copy-payment-link")
    btnClicked.setAttribute('open', '');
    (btnClicked === this.btnStandardTarget ? this.btnSecureTarget : this.btnStandardTarget).removeAttribute('open');
    if (btnCopy) {
      btnCopy.setAttribute("data-btn-copy-clipboard-link-value", btnClicked.dataset.link)
    }
  }
}
