import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["menu"];
  static values = {
    menuVisible: Boolean
  }

  initialize () {
    this.closeMenu = (e) => {
      if(!this.menuTarget.classList.contains("hidden") && !this.menuTarget.contains(e.target)) {
        this.menuTarget.classList.add("hidden");
      }
      else if (!this.menuTarget.contains(e.target)){
        this.menuVisibleValue = false;
      }
    }
  }
  connect() {
    document.addEventListener("mousedown", this.closeMenu);
  }

  disconnect() {
    document.removeEventListener("mousedown", this.closeMenu);
  }

  openMenu() {
    if (!this.menuVisibleValue) {
      this.menuTarget.classList.remove("hidden");
    }
    this.menuVisibleValue = !this.menuVisibleValue;
  }
}
