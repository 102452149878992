import ApplicationController from '.././application_controller'
import intlTelInput from 'intl-tel-input'
import utils from 'intl-tel-input/build/js/utils'

var intl;

export default class extends ApplicationController {
  static targets = [
    "phone",
    "countdownButton",
    "spinner",
    "spinnerCounter",
    "smsTwilioChannel",
    "callTwilioChannel",
    "smsTwilioChannelLabel",
    "callTwilioChannelLabel"
  ];

  connect() {
    super.connect();
    this.initIntlTelInput()
    this._buttonCountdown()
  }

  resendCodeSuccess() {
    this._buttonCountdown()
    this._disableButton(document.querySelector("[type=submit]"))
  }

  phoneOperations(e) {
    let phoneField = document.querySelector("[name='user[phone]']");
    let phoneIsValid = intl.isValidNumber();

    if (!e.target.value) {
      phoneField.value = "";
    } else {
      phoneField.value = intl.getNumber();
      
      if (phoneIsValid) {
        phoneField.dataset.phoneInvalid = false;
        delete phoneField.dataset.phoneErrorCode;
      } else {
        phoneField.dataset.phoneInvalid = true;
        phoneField.dataset.phoneErrorCode = intl.getValidationError();
      }
    };
    
    phoneField.dispatchEvent(new Event("change"));
  }

  initIntlTelInput(){
    if(!this.hasPhoneTarget) return;
    
    intl = intlTelInput(this.phoneTarget, {
      utilsScript: utils,
      preferredCountries: ["co", "ca", "cl", "ar", "ve", "pe"]
    })
  }

  setTwilioChannel(e) {
    this.countdownButtonTarget.dataset.twilioChannel = e.target.value
  }

  _buttonCountdown() {
    if (!this.hasCountdownButtonTarget) return;

    let button = this.countdownButtonTarget

    this._disableChannelOptions();
    button.href = "javascript: void(0)"
    delete button.dataset.reflex
    button.classList.add("disabled");
    this._startCountdown(button, 40)
  }
  
  _disableButton(button) {
    button.disabled = true
    button.classList.add("hover:bg-green-600", "opacity-50", "cursor-not-allowed")
  }

  _startCountdown(target, waitingTime) {
    let secondsRemaining = waitingTime;
    let spinner = this.spinnerTarget;
    let spinnerCounter = this.spinnerCounterTarget;

    spinnerCounter.innerText = `${secondsRemaining}`;
    spinner.classList.remove("hidden");

    let countdown = setInterval(() => {
      secondsRemaining -= 1;
      spinnerCounter.innerText = `${secondsRemaining}`;
    }, 1000);

    setTimeout(() => {
      clearInterval(countdown);
      target.disabled = false;
      target.classList.remove("disabled");
      target.dataset.reflex = "click->Verification::Phone#resend_code"
      spinner.classList.add("hidden");
      this._enableChannelOptions();
    }, (waitingTime * 1000));
  }

  _disableChannelOptions() {
    this.smsTwilioChannelTarget.disabled = true;
    this.smsTwilioChannelTarget.classList.add("cursor-not-allowed");
    this.smsTwilioChannelLabelTarget.classList.add("text-gray-300", "cursor-not-allowed");

    this.callTwilioChannelTarget.disabled = true;
    this.callTwilioChannelTarget.classList.add("cursor-not-allowed");
    this.callTwilioChannelLabelTarget.classList.add("text-gray-300", "cursor-not-allowed");
  }

  _enableChannelOptions() {
    this.smsTwilioChannelTarget.disabled = false;
    this.smsTwilioChannelTarget.classList.remove("cursor-not-allowed");
    this.smsTwilioChannelLabelTarget.classList.remove("text-gray-300", "cursor-not-allowed");

    this.callTwilioChannelTarget.disabled = false;
    this.callTwilioChannelTarget.classList.remove("cursor-not-allowed");
    this.callTwilioChannelLabelTarget.classList.remove("text-gray-300", "cursor-not-allowed");
  }
}
