import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for NavReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["sidebar"];

  connect() {
    super.connect();
    StimulusReflex.register(this);
    this.sidebar_right = document.querySelector("#right_sidebar");
    this.sidebar = this.hasSidebarTarget
      ? this.sidebarTarget
      : document.getElementById(this.element.dataset.sidebarId);
    this.breakpoint = parseInt(this.sidebar.dataset.breakpoint);

    this.resizeEventListener;
    if (this.hasSidebarTarget)
      this.resizeEventListener = window.addEventListener("resize", () =>
        window.innerWidth >= this.breakpoint ? this.open() : this.close()
      );

    this.openRightSidebarHandler = () => {
      if (!this.sidebar_right.hasAttribute("open")) {
        this.sidebar_right.setAttribute("closed", "");
        this.sidebar_right.setAttribute("open", "");
        this.openOverlay();
      } else if (this.sidebar_right.hasAttribute("closed")) {
        this.sidebar_right.removeAttribute("closed");
      }
    };

    if (this.hasSidebarTarget && this.sidebar.id === "right_sidebar") {
      document.addEventListener(
        "open-right-sidebar",
        this.openRightSidebarHandler
      );
    }

    let stateSidebar = localStorage.getItem(this.element.id);
    if (this.hasSidebarTarget && stateSidebar) {
      if (stateSidebar === "open") this.open();
      if (stateSidebar === "close") this.close();
    }
  }

  disconnect() {
    if (this.resizeEventListener)
      window.removeEventListener("resize", this.resizeEventListener);
    if (this.hasSidebarTarget && this.sidebar.id === "right_sidebar") {
      document.removeEventListener(
        "open-right-sidebar",
        this.openRightSidebarHandler
      );
    }
  }

  open() {
    this.sidebar.setAttribute("open", "");
    this.openOverlay();
    if (this.hasSidebarTarget) localStorage.setItem(this.element.id, "open");
  }

  openOverlay() {
    if (window.innerWidth < this.breakpoint) {
      document.getElementById("overlay").classList.remove("hidden");
      document.querySelector("html").style.overflow = "hidden";
    }
  }

  close() {
    this.sidebar.removeAttribute("open");
    document.getElementById("overlay").classList.add("hidden");
    document.querySelector("html").style.overflow = "";
    if (this.hasSidebarTarget) localStorage.setItem(this.element.id, "close");
  }

  toggle() {
    if (this.sidebar.hasAttribute("open")) this.close();
    else this.open();
  }

  back_to_activities(e) {
    e.preventDefault();
    this.stimulate("TradingReflex#hide_offer_details", this.sidebar_right);
    if (this.sidebar_right.hasAttribute("closed")) {
      this.close();
      this.sidebar_right.removeAttribute("closed");
    } else {
      let oldMarked = document.querySelector(".avatar-img.border-3:not(.active-trade)");
      if (oldMarked != undefined) oldMarked.classList.remove("border-3");
    }
  }
}
