import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["price", "priceSymbol", "button"];

  hide(e) {
    let hideSelector = e.currentTarget.dataset["hideSelector"];
    document.querySelectorAll(hideSelector).forEach((element) => {
      element.classList.add("hidden");
    });

    let statusSelector = e.currentTarget.dataset["statusSelector"];
    document.querySelectorAll(statusSelector).forEach((element) => {
      element.removeAttribute('open')
    });
  }

  show(e) {
    let hideSelector = e.currentTarget.dataset["hideSelector"];
    document.querySelectorAll(hideSelector).forEach((element) => {
      element.classList.remove("hidden");
    });

    let statusSelector = e.currentTarget.dataset["statusSelector"];
    document.querySelectorAll(statusSelector).forEach((element) => {
      element.setAttribute('open', '')
    });
  }

  toggleVisibility(e) {
    let hideSelector = e.currentTarget.dataset["hideSelector"];
    if (document.querySelector(hideSelector).classList.contains("hidden")) {
      e.currentTarget.open = true;
      document.querySelector("#TradeTimer").classList.remove("mt-[50px]");
      this.show(e)      
    } else {
      e.currentTarget.open = false;
      document.querySelector("#TradeTimer").classList.add("mt-[50px]");
      this.hide(e)
    }
  }

  toggleBalanceVisibility() {
    if (this.priceSymbolTargets[0].hasAttribute('open')) this.hideBalance();
    else this.showBalance();

    let visibility = this.priceSymbolTargets[0].hasAttribute('open')
    let user = this.priceSymbolTargets[0].dataset['userUuid']
    this.stimulate("Users::Settings::ProfileReflex#toggle_balance_visibility", user, visibility);
  }

  showBalance() {
    this.priceSymbolTargets.map($price_symbol => $price_symbol.setAttribute('open', ''))
  }
  
  hideBalance() {
    this.priceSymbolTargets.map($price_symbol => $price_symbol.removeAttribute('open'))
  }
}
