import ApplicationController from './application_controller';
import { Chart } from 'chart.js/auto'

export default class extends ApplicationController {
  static targets = ['walletCoinChart'];

  initialize() {
    this.updateChart = (event) => {
      this.initializeHistoryChart();
    };
  }

  connect() {
    this.walletCoinChartTarget.addEventListener("updateChart", this.updateChart);
    this.initializeHistoryChart();
  }

  disconnect() {
    this.walletCoinChartTarget.removeEventListener("updateChart", this.updateChart);
  }

  hexToRGBA(hex, opacity){
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(l => parseInt(hex.length%2 ? l+l : l, 16)).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
  }

  initializeHistoryChart(){
    if (!this.hasWalletCoinChartTarget) return;

    const ctx = this.walletCoinChartTarget.getContext("2d");
    const gradientFill = ctx.createLinearGradient(0, 0, 0, 175);
    gradientFill.addColorStop(0, this.hexToRGBA(this.walletCoinChartTarget.dataset.darkColor, 0.6));
    gradientFill.addColorStop(1, "#e8e8e8");
    if (this.walletChart) this.walletChart.destroy();
    const yAxe = this.walletCoinChartTarget.dataset.stableCoin === "true" ? { min: 0, max: 2, ticks: { stepSize: 0.1 } } : {};
    this.walletChart = new Chart(this.walletCoinChartTarget, {
      type: "line",
      data: {
        labels: this.walletCoinChartTarget.dataset.labels.split(';'),
        datasets: [
          {
            label: "USD",
            data: this.walletCoinChartTarget.dataset.rates.split(';'),
            borderColor: this.walletCoinChartTarget.dataset.darkColor,
            fill: "start",
            backgroundColor: gradientFill,
            radius: 1,
            pointStyle: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        bezierCurve: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            display: false,
            ...yAxe
          },
          x: {
            display: false,
          },
        },
        maintainAspectRatio: false
      },
    });
  }
}
