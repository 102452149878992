import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["overlay", "notification"];

  initialize () {
    this.closeMenu = (e) => {
      if(!this.overlayTarget.classList.contains("hidden") && !this.notificationTarget.contains(e.target)) {
        this.overlayTarget.classList.add("hidden");
      }
    }
  }
  connect() {
    document.addEventListener("mousedown", this.closeMenu);
  }

  disconnect() {
    document.removeEventListener("mousedown", this.closeMenu);
  }

  openMenu() { 
    this.overlayTarget.classList.remove("hidden");
  }
}
