import ApplicationController from "./application_controller";
import priceUpdater from "lib/reactivePriceUpdater";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [
    "amount",
    "amountSection",
    "advancedOptionsSection",
    "advancedOptionButton",
    "hiddenAdvancedOptionButton",
    "advancedFilterModalDrop",
    "advancedFilterMobileDrop",
    "advancedFilterDrop",
  ];

  connect() {
    StimulusReflex.register(this);

    //this.initAdvancedSectionVisibility()
    //this.amountSectionVisibility()
    this.initAmountCleave();
    this.onPopState();
    document.addEventListener("mark-avatar-image", this.roundedAvatarImage);
  }

  initAmountCleave() {
    if (this.hasAmountTarget) {
      new Cleave(this.amountTarget, {
        numeral: true,
        prefix: "$ ",
        noImmediatePrefix: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 2,
      });
    }
  }

  changeAmount(event) {
    let { value, dataset } = event.target;
    dataset.valueWithoutFormat = value.replaceAll("$ ", "").replaceAll(",", "");
  }

  filterByAmount() {
    if (this.amountTarget.value) this.search_form(this.amountTarget);
  }

  toggleAdvancedFilters() {
    if (this.hasAdvancedFilterModalDropTarget) {
      this.advancedFilterModalDropTarget.classList.toggle("hidden");
      this.advancedFilterMobileDropTarget.classList.toggle("hidden");
    }
    this.advancedFilterDropTarget.classList.toggle("hidden");
  }

  search_form(target = event.currentTarget) {
    this.stimulate("OffersReflex#search_form", target);
  }

  afterRefreshMarket() {
    priceUpdater.update();
  }

  urlState() {
    var state = { path: this.urlParams };
    var title = "";
    var path = `/market/${this.urlParams}`;

    window.history.pushState(state, title, path);
  }

  onPopState() {
    window.onpopstate = function (event) {
      if (event && event.state) {
        Turbolinks.visit(location, { action: "replace" });
      }
    };
  }

  amountSectionVisibility() {
    var currency = this.queryVariables.currency;
    this.amountSectionTarget.hidden = currency == "default";
  }

  initAdvancedSectionVisibility() {
    this.advancedOptionsSectionTarget.style.display = !this
      .isAdvanceSectionVisible
      ? "none"
      : "";
    this.advancedOptionButtonTarget.hidden = this.isAdvanceSectionVisible;
    this.hiddenAdvancedOptionButtonTarget.hidden =
      !this.isAdvanceSectionVisible;
  }

  advancedOptionVisibility() {
    var hidden = this.advancedOptionsSectionTarget.style.display == "none";
    this.swapAdvanceVisibility();

    if (hidden) return;

    var q = this.queryVariables;
    this.clearFiltersAdvancedSection();
    if (q.currency != "default" || q.text_search) {
      this.updateSelectedValue("currency", "default");
      this.search_form(event);
    }
  }

  updateSelectedValue(id, value) {
    var currencySelect = this.application.controllers.find(
      (controller) => controller.data.get("id") == id
    );

    if (!currencySelect) return;

    currencySelect.unselectValue();
    currencySelect.selectValue(value);
  }

  swapAdvanceVisibility() {
    this.hiddenAdvancedOptionButtonTarget.hidden =
      !this.hiddenAdvancedOptionButtonTarget.hidden;
    this.advancedOptionButtonTarget.hidden =
      !this.advancedOptionButtonTarget.hidden;
    this.advancedOptionsSectionTarget.style.display = this
      .hiddenAdvancedOptionButtonTarget.hidden
      ? "none"
      : "";
  }

  clearFiltersAdvancedSection() {
    $("#amount").val("");
    $("#text_search").val("");
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.stimulate("OffersReflex#refresh_market");
    }, this.refreshSeconds);
  }

  resetRefresh(e) {
    const time = e.target.value;
    if (time === "none") {
      this.stopRefreshing();
    } else {
      this.refreshSeconds = time;
      this.stopRefreshing();
      this.startRefreshing();
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  get urlParams() {
    return this.data.get("urlParams");
  }

  get queryVariables() {
    return JSON.parse(this.data.get("q"));
  }

  get isAdvanceSectionVisible() {
    return JSON.parse(this.data.get("advancedSectionVisible"));
  }

  beforeReflex() {
    this.benchmark = performance.now();
  }

  setSelectedValue(id, value, byCode) {
    let ctrlSelect = this.application.controllers.find(
      (controller) => controller.data.get("id") == id
    );
    if (!ctrlSelect) return;
    ctrlSelect._unselectValue();
    byCode
      ? ctrlSelect._selectValue(value)
      : ctrlSelect._selectValueByDescription(value);
  }

  afterReflex(element, reflex) {
    if (process.env.NODE_ENV != "production")
      console.log(
        reflex,
        `${(performance.now() - this.benchmark).toFixed(0)}ms`
      );

    setTimeout(() => {
      this.setSelectedValue("location", this.queryVariables.location, false);
      this.setSelectedValue(
        "payment_method_ids",
        this.queryVariables.payment_method_ids,
        true
      );
    }, 300);
  }

  roundedAvatarImage(e) {
    let isMarkable = e.detail?.isMarkable;
    let imgItem = document.activeElement
      .closest("div[name=offer-container]")
      .querySelector(".avatar-img");

    let oldMarked = document.querySelectorAll(
      ".avatar-img.border-3.border-with_opacity-blue"
    );
    if (oldMarked.length > 0){
      oldMarked.forEach((item)=>{
        item.classList.remove("border-3", "border-with_opacity-blue");
      })
    } 

    if (imgItem != undefined && isMarkable) imgItem.classList.add("border-3", "border-with_opacity-blue");
  }

  disconnect() {
    this.stopRefreshing();
    document.removeEventListener("mark-avatar-image", this.roundedAvatarImage);
  }
}
