import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["timer"];

  initialize() {
    this.MILLISECONDS_OF_A_SECOND = 1000;
    this.MILLISECONDS_OF_A_MINUTE = this.MILLISECONDS_OF_A_SECOND * 60;
    this.MILLISECONDS_OF_A_HOUR = this.MILLISECONDS_OF_A_MINUTE * 60;
    this.MILLISECONDS_OF_A_DAY = this.MILLISECONDS_OF_A_HOUR * 24;
  }

  connect() {
    super.connect();
    if (this.hasTimerTarget) {
        const timerElement = this.timerTarget;
        const created_date = timerElement.dataset.createdDate
      this.interval = setInterval(() => {
        if(!!created_date){
            this.updateCountdown(new Date(created_date))
        }
      }, this.MILLISECONDS_OF_A_SECOND);
    }
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  updateCountdown(CREATED_DATE) {
    const NOW = new Date()
    const DURATION = NOW - CREATED_DATE;
    const REMAINING_DAYS = Math.floor(DURATION / this.MILLISECONDS_OF_A_DAY);
    const REMAINING_HOURS = Math.floor((DURATION % this.MILLISECONDS_OF_A_DAY) / this.MILLISECONDS_OF_A_HOUR);
    const REMAINING_MINUTES = Math.floor((DURATION % this.MILLISECONDS_OF_A_HOUR) / this.MILLISECONDS_OF_A_MINUTE);
    const REMAINING_SECONDS = Math.floor((DURATION % this.MILLISECONDS_OF_A_MINUTE) / this.MILLISECONDS_OF_A_SECOND);
    this.timerTarget.innerHTML = `${!!REMAINING_DAYS ? REMAINING_DAYS.toString() + "d" : ""} ${!!REMAINING_HOURS ? REMAINING_HOURS.toString() + "h": ""} ${REMAINING_MINUTES.toString().padStart(2, "0")}:${REMAINING_SECONDS.toString().padStart(2, "0")}`
  }
}
