import moment from 'moment'

const humanFileSize = (size) => {
  let i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const formatMessage = (currentUser, otherUser, message) => {
  const author = currentUser.identity == message.author ? currentUser : otherUser,
        formatedMessage = {
          id: message.sid,
          index: message.state.index,
          text: message.body,
          datetime: moment(message.timestamp).calendar(),
          attachment: message.attributes.attachment,
          isMine: message.author == currentUser.identity,
          author: author,
          type: message.attributes.type || 'normalMessage'
        }

  if(formatedMessage.attachment){
    formatedMessage.attachment.size = humanFileSize(formatedMessage.attachment.size)
  }

  return formatedMessage
}

export const csrf_token = () => {
  if(process.env.NODE_ENV == 'test') {
    return ''
  } else {
    return document.getElementsByName('csrf-token')[0].getAttribute('content')
  }
}

const pad = (value, digits) => {
  return String(value).padStart(digits, '0')
}

export const numberToClockTime = (number) => {
  let hrs = ~~(number / 3600)
  let min = ~~((number % 3600) / 60)
  let sec = ~~number % 60
  return `${pad(hrs, 2)}:${pad(min, 2)}:${pad(sec, 2)}`
}

export const getCurrentTime = () => {
  let options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
  return new Date().toLocaleTimeString('en-US', options).toLowerCase()
}
