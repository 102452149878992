import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  hideElement(el) {
    document.getElementById(el).style.display = 'none';
  }

  toggleDeposit() {
    const element = document.getElementById('deposit-container');

    this.hideElement('withdrawal-container');

    if (element.style.display === 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }

  toggleWithdrawal() {
    const element = document.getElementById('withdrawal-container');

    this.hideElement('deposit-container');

    if (element.style.display === 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }
}
