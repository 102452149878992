import ApplicationController from './application_controller';
// import StimulusReflex from "stimulus_reflex";

const pointerClass = 'pointer-events-none';
const hiddenClass = 'hidden';
const breakpointSize = 1024
const smallScreenCount = 25
const bigScreenCount = 20

export default class extends ApplicationController {
    static targets = ['transactions', 'coinsContainer', 'leftArrow', 'rightArrow' ];

    initialize() {
        this.defineWidth = () => {
            this.coinsContainerTarget.style.width = `${this.totalCoins() * this.stepSize()}%`
        }
    }

    connect() {
        this.defineWidth();
        this.showElements();
        window.addEventListener("resize", this.defineWidth);
    }
    disconnect() {
        window.removeEventListener("resize", this.defineWidth);
    }

    showElements() {
        let newRight = this.coinsContainerTarget.dataset.scrollIndex * this.stepSize();

        this.coinsContainerTarget.style.right = `${Math.min(this.maxRight(), newRight)}%`
        this.disableArrows()
        this.coinsContainerTarget.classList.remove(hiddenClass)
        this.leftArrowTarget.classList.remove(hiddenClass)
        this.rightArrowTarget.classList.remove(hiddenClass)
    }

    disableArrows() {
        if (this.rightValue() <= 0) {
            this.leftArrowTarget.classList.add(pointerClass)
        } else {
            this.leftArrowTarget.classList.remove(pointerClass)
        }

        if (this.maxRight() - this.rightValue() <= 0) {
            this.rightArrowTarget.classList.add(pointerClass)
        } else {
            this.rightArrowTarget.classList.remove(pointerClass)
        }
    }

    scrollRight() {
        if (this.rightValue() < this.maxRight()) {
            this.coinsContainerTarget.style.right = `${this.rightValue() + this.stepSize()}%`
            this.coinsContainerTarget.dataset.scrollIndex = parseInt(this.coinsContainerTarget.dataset.scrollIndex) + 1;
        }
        this.disableArrows()
    }

    scrollLeft() {
        if (this.rightValue() > 0) {
            this.coinsContainerTarget.style.right = `${this.rightValue() - this.stepSize()}%`
            this.coinsContainerTarget.dataset.scrollIndex = parseInt(this.coinsContainerTarget.dataset.scrollIndex) - 1;
        }
        this.disableArrows()
    }

    stepSize() {
        return window.innerWidth >= breakpointSize ? bigScreenCount : smallScreenCount
    }

    totalCoins() {
        return this.transactionsTarget.dataset.coinsCount
    }

    rightValue() {
        return parseInt(this.coinsContainerTarget.style.right) || 0
    }

    maxRight() {
        let coinsToShow = 100 / this.stepSize()
        return (this.totalCoins() - coinsToShow) * this.stepSize()
    }

    show_account(e) {
        if (!this.hasCoinsContainerTarget || !e.currentTarget.dataset.url) return;
        window.location.href = `${e.currentTarget.dataset.url}?scroll_index=${this.coinsContainerTarget.dataset.scrollIndex}`;
    }
}