import Cleave from 'cleave.js'
import { Controller } from '@hotwired/stimulus'
import priceUpdater from 'lib/reactivePriceUpdater'
import axiosInstance from 'lib/axiosInstance'

export default class extends Controller {
  static targets = ["amountFiat", "amountCrypto", "form"]

  connect() {
    var controller = this;
    this.fiatField = new Cleave(this.amountFiatTarget, {numeral: true, numeralPositiveOnly: true})
    this.cryptoField = new Cleave(this.amountCryptoTarget, {numeral: true, numeralDecimalScale: this.precision, numeralPositiveOnly: true})
    controller.offerIsPaused()

    $(this.formTarget).on("ajax:error", function(event) {
      var [errors, status, xhr] = event.detail;
      if(controller.offerPaused == "false") controller.showErrors(this, errors);
    });

    $(this.formTarget).on("ajax:beforeSend", function(event) {
      controller.offerIsPaused();
      $(this).find(".errors-wrapper").hide();
      $(this).find(".c-offer__trade-form__fieldset__field-error").remove();
      $(this).find(".c-offer__trade-form__fieldset__field__input").removeClass("border-red");
      $(this).find(".c-offer__trade-form__fieldset__field__input__label__content").removeClass("border-red");
    });

    priceUpdater.onUpdate(function() {
      controller.cryptoChanged()
    })
    controller.cryptoChanged()
  }

  showErrors(form, errors) {
    var $form = $(form);
    for(var field in errors) {
      var formField = $form.find(`[name='trade[${field}]']`);
      if(formField) {
        var fieldWrapper = $(formField).closest('.c-offer__trade-form__fieldset__field-wrapper');
        fieldWrapper.find(".c-offer__trade-form__fieldset__field__input__label__content").addClass("border-red");
        formField.addClass("border-red");
        errors[field].forEach(function(error) {
          fieldWrapper.append($("<p>").addClass("c-offer__trade-form__fieldset__field-error").text(error));
        });
      }
      else {
        $form.find('.errors-wrapper').show();
        $form.find('.errors-list').append($("<li></li>").text(errors[field]));
      }
    }
  }

  offerIsPaused(){
    axiosInstance.get(`/offers/${this.offerUuid}/paused`)
    .then(function (response) {
      this.data.set('offer-paused', response.data.paused)
      if (this.offerPaused == "true") {
       this.hiddenOpenTradeButton()
     } else {
       this.hiddenOfferPausedInfo()
     }

    }.bind(this))
    .catch(function (error) {
      console.log(error);
    })
  }

  hiddenOpenTradeButton() {
    $(".offer-paused-info").removeClass("hidden");
    $(".open-trade").addClass("hidden");
  }

  hiddenOfferPausedInfo() {
    $(".open-trade").removeClass("hidden");
    $(".offer-paused-info").addClass("hidden");
  }

  async fiatChaged() {
    const [err, rate] = await this.rate
    if(err) throw new Error(err)

    var amountCrypto = this.amountFiat / rate;
    if(amountCrypto < 1e-6)
      amountCrypto = 0;
    this.cryptoField.setRawValue(amountCrypto);
    this.amountCryptoTarget.dispatchEvent(new Event('change'))
  }

  async cryptoChanged() {
    const [err, rate] = await this.rate
    if(err) throw new Error(err)
    this.fiatField.setRawValue(this.amountCrypto * rate)
    this.amountFiatTarget.dispatchEvent(new Event('change'))
  }

  get precision() {
    return parseInt(this.data.get('precision'))
  }

  get offerUuid() {
    return this.data.get('offer-uuid')
  }

  get offerPaused(){
    return this.data.get('offer-paused')
  }

  get amountFiat() {
    return parseFloat(this.fiatField.getRawValue())
  }

  get amountCrypto() {
    return parseFloat(this.cryptoField.getRawValue())
  }

  get rate() {
    return priceUpdater.calculateNewPrice(this.priceFormula)
  }

  get priceFormula() {
    return this.data.get('price-formula')
  }
}
