import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['passwordInput', 'showItem', 'hideItem']

  show() {
    this.passwordInputTarget.type = "text";
    this.showItemTarget.classList.add("hidden");
    this.hideItemTarget.classList.remove("hidden");
  }

  hide() {
    this.passwordInputTarget.type = "password";
    this.hideItemTarget.classList.add("hidden");
    this.showItemTarget.classList.remove("hidden");
  }

  toggle() {
    if (this.showItemTarget.classList.contains("hidden")) this.hide();
    else this.show();
  }
}
