import ApplicationController from '../../application_controller'
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Parser } from 'expr-eval'
import {
    subscribeBitgoChannel,
    cryptoToUsd,
    convertUsdToUserCurrency,
    usdToCrypto,
    userCurrencyToUsd,
    formatValueCleave,
    getRate,
    setupClickOutside,
    setAmountButtonStyle,
    getDataValue,
    getNumericValue,
    setNewValueToTarget
} from '../../../lib/flip/helpers.js'
import { playSoundNotification } from 'lib/soundNotifications'
import Decimal from 'decimal.js'

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

var cryptoCoins = [];
let controller;

export default class extends ApplicationController {
    static values = { url: String }

    static targets = ["rateSpanInfo", "amountFrom", "amountTo",
        "toReceiveAmount", "toConvertAmount", "amountFromSymbol",
        "amountToSymbol", "amountFromUserCurrency", "amountToUserCurrency",
        "amountFromInputUserCurrency", "amountToInputUserCurrency",
        "flipShowPage", "maxBalance","halfBalance", "maxBalanceAmountFrom",
        "maxBalanceAmountTo","maxBalanceAmountFromUserCurrency",
        "maxBalanceAmountToUserCurrency", "flipSuccessPage"]

    amountInputObserver$ = new Subject();
    parseValues = new Parser();

    initialize() {
        this.activateLoadFromTriggersHandler = (event) => {
            this.stimulate("Users::Wallets::FlipReflex#update_flip_input_from_js", "to_convert", this.getCoinValue("receive"),
              this.getCoinValue("convert"), getNumericValue(this.amountFromTarget), this.is_recharge(), this.is_modal_view());
        }
        this.activateLoadToTriggersHandler = (event) => {
            this.stimulate("Users::Wallets::FlipReflex#update_flip_input_from_js", "to_receive", this.getCoinValue("receive"),
              this.getCoinValue("convert"), getNumericValue(this.amountToTarget), this.is_recharge(), this.is_modal_view());
        }
    }

    connect(){
        super.connect();
        if (this.hasFlipShowPageTarget){
            controller = this;
            cryptoCoins = this.flipShowPageTarget.getAttribute('data-crypto-coins').split(',');
            if (process.env.NODE_ENV != "production"){
                cryptoCoins.push("TUSD*");
            }
            this.amountFromTarget.setAttribute('data-fixed', "true");
            // setup observable amount inputs
            this.setupAmountInput();
            setupClickOutside();
            subscribeBitgoChannel(controller);
            this.updatePrecisionInputs();
            this.updateBalanceInfo();
            this.successFlip();
        }
        this.countdown();
        document.addEventListener("activate-load-from-triggers", this.activateLoadFromTriggersHandler);
        document.addEventListener("activate-load-to-triggers", this.activateLoadToTriggersHandler);
    }

    disconnect() {
        document.removeEventListener("activate-load-from-triggers", this.activateLoadFromTriggersHandler);
        document.removeEventListener("activate-load-to-triggers", this.activateLoadToTriggersHandler);
    }

    countdown() {
        this.time_window = 30 * 1000; // 30 seconds
        this.expiration = new Date().getTime() + this.time_window;
        this.interval = setInterval(() => {
            const missing_time = this.expiration - new Date().getTime();
            if (missing_time < 0) {
                this.stimulate("Users::Wallets::FlipReflex#show_rate_info_from_ids", this.getCoinValue("receive"), this.getCoinValue("convert"));
                this.expiration = new Date().getTime() + this.time_window;
            }
        });
    }

    updateInfo(){
        this.updateValues();
        this.updateBalanceInfo();
    }

    updateValues(){
        let target = document.querySelector('[data-fixed="true"]');
        // notify to observer
        if (target != null && !Number.isNaN(Number(target.value)))
            this.amountInputObserver$.next(target);
    }

    setFixedInput(target) {
        this.amountFromTarget.setAttribute('data-fixed', "false");
        this.amountToTarget.setAttribute('data-fixed', "false");
        this.amountFromInputUserCurrencyTarget.setAttribute('data-fixed', "false");
        this.amountToInputUserCurrencyTarget.setAttribute('data-fixed', "false");
        target.setAttribute('data-fixed', "true");
    }

    setBalanceValue(e){
        e.preventDefault();
        let precision = parseInt(getDataValue(this.amountFromTarget, "precision"));
        let value = new Decimal(getDataValue(e.target, "value")).toFixed(precision);
        let currency_value = parseFloat(getDataValue(e.target, "currency-value"));        
                
        setAmountButtonStyle(this, e.target);
        setNewValueToTarget(this.amountFromTarget, value);
        setNewValueToTarget(this.amountFromInputUserCurrencyTarget, currency_value);
        const cryptoInput = document.getElementById("swap_operation_amount_from");
        cryptoInput.value = value;        
        this.amountInputObserver$.next(this.amountFromTarget);

        this.stimulate("Users::Wallets::FlipReflex#update_flip_input_from_js", "to_convert", this.getCoinValue("receive"),
          this.getCoinValue("convert"), getNumericValue(this.amountFromTarget), this.is_recharge(), this.is_modal_view());
    }

    getCoinValue(inputType){
        return document.querySelector(`#swap_operation_to_${inputType}`)?.value;
    }

    afterSelectedCoin(){
        this.cleanInputValues();
        this.updatePrecisionInputs();
    }

    beforeCreateFlip(){
        let buttons = document.querySelectorAll(".modal-confirm-btn");
        buttons.forEach(el => el.classList.add("hidden"));
        let spinner = document.querySelector("#ccoins-spinner");
        spinner?.classList.remove("hidden");
    }

    cleanInputValues(){
        this.amountFromTarget.value = "";
        this.amountFromInputUserCurrencyTarget.value = "";
        this.amountToTarget.value = "";
        this.amountToInputUserCurrencyTarget.value = "";
    }

    updateBalanceInfo(){
        let maxBalanceAmountFrom = new Decimal(this.maxBalanceAmountFromTarget.innerText);
        let maxBalanceAmountTo = new Decimal(this.maxBalanceAmountToTarget.innerText);
        let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
        let symbolTo = this.amountToTarget.getAttribute('data-symbol');
        let maxBalanceAmountFromUsd = cryptoToUsd(maxBalanceAmountFrom, getRate(symbolFrom, "sell_price"));
        let maxBalanceAmountToUsd = cryptoToUsd(maxBalanceAmountTo, getRate(symbolTo, "sell_price"));

        this.maxBalanceAmountFromUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.flipShowPageTarget, maxBalanceAmountFromUsd);
        this.maxBalanceAmountToUserCurrencyTarget.innerText = convertUsdToUserCurrency(this.flipShowPageTarget, maxBalanceAmountToUsd);
    }

    updateRateInfo(){
        let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
        let symbolTo = this.amountToTarget.getAttribute('data-symbol');
        let precision = parseInt(this.amountToTarget.getAttribute('data-precision'));
        let result = this.calculateAmountFromInputs(new Decimal(1), "to_receive", symbolFrom, symbolTo);
        let value = (new Decimal(result[2])).toFixed(precision);
        this.rateSpanInfoTarget.innerText = `1 ${symbolFrom} ≈ ${value} ${symbolTo}`;
    }

    getCoins(){
        let originCoin = document.getElementById("receive-coin").querySelector("[data-coin-selected='true']").dataset["coinId"];
        let destinationCoin = document.getElementById("convert-coin").querySelector("[data-coin-selected='true']").dataset["coinId"];
        let coins = [originCoin, destinationCoin];

        return coins;
    }

   setupAmountInput(){
        this.amountInputObserver$.pipe(
            map(e =>{
                let value = new Decimal(e.value == "" ? "0" : e.value);
                let type = e.getAttribute('data-type');
                let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
                let symbolTo = this.amountToTarget.getAttribute('data-symbol');

                return this.calculateAmountFromInputs(value, type, symbolFrom, symbolTo);
            })
        ).subscribe(result => {
            // update DOM
            // result = [originValue, usdValue, destinationValue, type]
            let precisionFrom = parseInt(this.amountFromTarget.getAttribute('data-precision'));
            let precisionTo = parseInt(this.amountToTarget.getAttribute('data-precision'));

            if (result[3] == "to_receive"){
                this.amountFromTarget.value = result[0];
                this.amountToTarget.value = (new Decimal(result[2])).toFixed(precisionTo);
                this.toConvertAmountTargets.forEach((el) => el.innerText = (new Decimal(result[2])).toFixed(precisionTo));
                this.toReceiveAmountTarget.innerText = (new Decimal(result[0])).toFixed(precisionFrom);
            } else if (result[3] == "to_convert"){
                this.amountToTarget.value = result[0];
                this.amountFromTarget.value = (new Decimal(result[2])).toFixed(precisionFrom);
                this.toReceiveAmountTarget.innerText = (new Decimal(result[2])).toFixed(precisionFrom);
                this.toConvertAmountTargets.forEach((el) => el.innerText = (new Decimal(result[0])).toFixed(precisionTo));
            } else if (["to_receive_user_currency", "to_convert_user_currency"].includes(result[3])){
                this.amountFromTarget.value = (new Decimal(result[0])).toFixed(precisionFrom);
                this.amountToTarget.value = (new Decimal(result[2])).toFixed(precisionTo);
                this.toConvertAmountTargets.forEach((el) => el.innerText = (new Decimal(result[2])).toFixed(precisionTo));
                this.toReceiveAmountTarget.innerText = (new Decimal(result[0])).toFixed(precisionFrom);
            }
            this.updateUserCurrencyValues();
            this.enableFlipButton();
        })

    }

    // return [originValue, usdValue, destinationValue, type]
    // CryptoToUsd use sell_price to get rate
    // usdToCrypto use buy_price to get rate
    calculateAmountFromInputs(value, type, symbolFrom, symbolTo){
        // Case modify Input to receive
        if (type == "to_receive"){
            let usdAmount = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(value, getRate(symbolFrom, "sell_price")) : value
            let cryptoValue = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));

            return cryptoCoins.includes(symbolTo) ? [value, usdAmount, cryptoValue, type] : [value, usdAmount, usdAmount, type] ;

        } // Case modify Input to convert
        else if (type == "to_convert"){
            let usdAmount = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(value, getRate(symbolTo, "buy_price")) : value
            let cryptoValue = usdToCrypto(usdAmount, getRate(symbolFrom, "sell_price"));

            return cryptoCoins.includes(symbolTo) ? [value, usdAmount, cryptoValue, type] : [value, usdAmount, usdAmount, type] ;
        } else if (["to_receive_user_currency", "to_convert_user_currency"].includes(type)){
            let userCurrency = this.flipShowPageTarget.getAttribute('data-user-currency');
            let userCurrencyUsdRate = new Decimal(this.flipShowPageTarget.getAttribute('data-user-currency-usd-rate'));
            let usdAmount = userCurrency == "USD" ? value : userCurrencyToUsd(value, userCurrencyUsdRate);
            let cryptoValueFrom = usdToCrypto(usdAmount, getRate(symbolFrom, "buy_price"));
            let cryptoValueTo = usdToCrypto(usdAmount, getRate(symbolTo, "buy_price"));

            return [cryptoValueFrom, usdAmount, cryptoValueTo, type];
        } else {
            return [];
        }
    }

    updateUserCurrencyValues(){
        let symbolFrom = this.amountFromTarget.getAttribute('data-symbol');
        let symbolTo = this.amountToTarget.getAttribute('data-symbol');
        const amountFromTargetDecimal = new Decimal(this.amountFromTarget.value);
        const amountToTargetDecimal = new Decimal(this.amountToTarget.value)
        let amountFromUserCurrencyUsd = cryptoCoins.includes(symbolFrom) ? cryptoToUsd(amountFromTargetDecimal, getRate(symbolFrom, "buy_price")) : amountFromTargetDecimal;
        let amountToUserCurrencyUsd = cryptoCoins.includes(symbolTo) ? cryptoToUsd(amountToTargetDecimal, getRate(symbolTo, "buy_price")) : amountToTargetDecimal;

        let amountFromUserCurrency = convertUsdToUserCurrency(this.flipShowPageTarget, amountFromUserCurrencyUsd);
        let amountToUserCurrency = convertUsdToUserCurrency(this.flipShowPageTarget, amountToUserCurrencyUsd);

        this.amountFromUserCurrencyTargets.forEach((el)=> el.innerText = amountFromUserCurrency);
        this.amountToUserCurrencyTargets.forEach((el)=> el.innerText = amountToUserCurrency);

        if (this.amountFromInputUserCurrencyTarget.getAttribute('data-fixed') == "false"){
            this.amountFromInputUserCurrencyTarget.value = amountFromUserCurrency;
        }

        if (this.amountToInputUserCurrencyTarget.getAttribute('data-fixed') == "false")
            this.amountToInputUserCurrencyTarget.value = amountToUserCurrency;
    }

    updatePrecisionInputs(){
        formatValueCleave(this.amountFromTarget, this.amountFromTarget.getAttribute('data-precision'));
        formatValueCleave(this.amountToTarget, this.amountToTarget.getAttribute('data-precision'));
    }

    successFlip(){
        const controller = this;
        document.addEventListener("flip-success", ()=>{
            playSoundNotification(this.urlValue);
        })
    }

    enableFlipButton(){
        let flipButton = document.getElementById("flip-btn");
        if (parseFloat(this.amountToTarget.value) > 0 ){
            flipButton.disabled = false;
            flipButton.classList.remove("disabled");
        } else {
            flipButton.disabled = true;
            flipButton.classList.add("disabled");
        }
    }

    is_recharge() {
        return document.querySelector('#swap_operation_is_recharge')?.value === 'true';
    }

    is_modal_view() {
        const btnSubmit = document.querySelector('#flip-button');
        return btnSubmit.dataset.modalView === 'true';
    }
}
