import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["buttonConfirm"];

  connect() {
    const alertModal = document.querySelector("#alert_p2p");
    const showModal = localStorage.getItem("SHOW_P2P_ALERT");
    if (alertModal && (showModal === "yes" || showModal === null)) {
      alertModal.classList.add("flex");
      alertModal.classList.remove("hidden");
    }
  }

  handleCheckboxField({ target }) {
    const checked = target.checked;
    if (this.buttonConfirmTarget) {
      this.buttonConfirmTarget.disabled = !checked;
    }
  }

  confirmAgreementPolicies() {
    const btnClose = document.querySelector("#alert_p2p #times-close-button");
    if (btnClose) {
      localStorage.setItem("SHOW_P2P_ALERT", "no");
      btnClose.click();
    }
  }
}
