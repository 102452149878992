const Uppy = require("@uppy/core")
const FileInput = require("@uppy/file-input")
const Informer = require("@uppy/informer")
const Tus = require("@uppy/tus")

require("@uppy/core/dist/style.css")
require('@uppy/file-input/dist/style.css')
require("@uppy/informer/dist/style.css")

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".upload-file").forEach(fileInput => {
    fileUpload(fileInput)
  })
})

function fileUpload(fileInput) {
  const hiddenInput = document.querySelector(".upload-data"),
        uploadPreview = document.querySelector(".upload-preview"),
        formGroup = fileInput.parentNode,
        submit = document.querySelector(".upload-submit")

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  let uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: false
    })
    .use(FileInput, {
      target: formGroup,
      pretty: true
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(Tus, {
      endpoint: "/files",     // path to our tus server
      chunkSize: 5*1024*1024, // required unless tus-ruby-server is running on Falcon
    })

  uppy.on("upload-success", (file, response) => {
    // show information about the uploaded file
    uploadPreview.innerHTML = `name: ${file.name}, type: ${file.type}, size: ${file.size}`

    // dispatch custom event for file validation
    let fileUploaded = new Event("file:uploaded")
    perform = submit.dispatchEvent(fileUploaded)

    // construct uploaded file data from the tus URL
    var uploadedFileData = {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1],
      storage: "cache",
      metadata: {
        filename: file.name,
        size: file.size,
        mime_type: file.type,
      }
    }

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
  })
}