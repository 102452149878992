import { Controller } from '@hotwired/stimulus'
import priceUpdater from 'lib/reactivePriceUpdater'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["amount", "buyButton", "currencySelect", "min", "max", "coinSelect", "location", "latitude", "longitude", "operation", "paymentMethodSelect", "searchForm", "sellButton"]

  connect () {
    const controller = this
    this.updateSelectedButton()
    $(this.coinSelectTarget).dropdown()
    $(this.paymentMethodSelectTarget).dropdown()
    $(this.currencySelectTarget).dropdown()

    const autocomplete = new google.maps.places.Autocomplete(this.locationTarget, { types: ['(cities)'] })
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if(place.geometry) {
        controller.latitudeTarget.value = place.geometry.location.lat()
        controller.longitudeTarget.value = place.geometry.location.lng()
        this.submitRemoteForm()
      }
    })

    $(this.searchFormTarget).change((event) => {
      if(!event.target.dataset.eventsIgnored) {
        this.submitRemoteForm()
      }
    })

    $(this.searchFormTarget).on('ajax:success', function() {
      priceUpdater.update()
    })
  }

  updateMaxLimit() {
    this.maxTarget.value = this.amountTarget.value
  }

  selectBuyOperation() {
    this.operationTarget.value = "sell" // If I want to buy, then offers should be selling
    this.selectBuyButton()
    this.submitRemoteForm()
  }

  selectSellOperation() {
    this.operationTarget.value = "buy" // If I want to sell, then offers should be buying
    this.selectSellButton()
    this.submitRemoteForm()
  }

  updateSelectedButton() {
    if (this.data.get("operation") == "sell") {
      this.selectBuyButton()
    } else {
      this.selectSellButton()
    }
  }

  selectBuyButton() {
    this.sellButtonTarget.classList.remove("offer-search-form__operation-btn--selected")
    this.buyButtonTarget.classList.add("offer-search-form__operation-btn--selected")
  }

  selectSellButton() {
    this.sellButtonTarget.classList.add("offer-search-form__operation-btn--selected")
    this.buyButtonTarget.classList.remove("offer-search-form__operation-btn--selected")
  }

  submitRemoteForm() {
    Rails.fire(this.searchFormTarget, 'submit')
  }
}
