import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["container", "swiper", "submit"];
  static values = {
    submitted: Boolean
  }

  initialize() {
    this.initialPositionSwiper =
      this.containerTarget.dataset?.initialPositionSwiper || "-230px";
    this.minimumDisplacementPosition =
      Number(this.containerTarget.dataset?.initialPositionSwiper) || 10;

    this.onDrag = (e) => {
      if (this.submittedValue) return;

      const initialPosition = this.containerTarget.getBoundingClientRect().left;
      const offsetWidthTarget = this.containerTarget.offsetWidth;
      const clientX = e.clientX;
      const traveledSpace = clientX - initialPosition;
      const minimumPosition = offsetWidthTarget - this.minimumDisplacementPosition;
    
      if (traveledSpace >= minimumPosition) {
        this.swiperTarget.style.left = `${minimumPosition - offsetWidthTarget}px`;
        this.submitTarget?.click();
        this.submittedValue = true;
        this.containerTarget.classList.add("disabled");
        this.swiperTarget.classList.remove("cursor-pointer");
        const processingMessage = document.getElementById('processing-message');
        if (processingMessage) {
          processingMessage.classList.remove('hidden');
        }
      } else if (traveledSpace > this.minimumDisplacementPosition) {
        this.swiperTarget.style.left = `${traveledSpace - offsetWidthTarget}px`;
      } else {
        this.swiperTarget.style.left = this.initialPositionSwiper;
      }
    };

    this.activeSwiper = () => {
      this.containerTarget?.addEventListener("mousemove", this.onDrag);
    };

    this.desactiveSwiper = () => {
      const offsetWidthTarget = this.containerTarget.offsetWidth;
      const swiperLeft = Number(this.swiperTarget.style.left.replace("px", ""));
      const minimumPosition = offsetWidthTarget - this.minimumDisplacementPosition;
      
      this.containerTarget?.removeEventListener("mousemove", this.onDrag);
      
      if (swiperLeft >= minimumPosition) {
        this.submitTarget?.click();
        this.containerTarget.classList.add("disabled");
        const processingMessage = document.getElementById('processing-message');
        if (processingMessage) {
          processingMessage.classList.remove('hidden');
        }
      }

      this.swiperTarget.style.left = this.initialPositionSwiper;
    };
  }

  connect() {
    if (this.hasSwiperTarget && this.hasContainerTarget) {
      this.swiperTarget?.addEventListener("mouseup", this.desactiveSwiper);
      this.swiperTarget?.addEventListener("mousedown", this.activeSwiper);
      this.containerTarget?.addEventListener(
        "mouseleave",
        this.desactiveSwiper
      );
    }
  }

  disconnect() {
    if (this.hasSwiperTarget && this.hasContainerTarget) {
      this.swiperTarget?.removeEventListener("mouseup", this.desactiveSwiper);
      this.swiperTarget?.removeEventListener("mousedown", this.activeSwiper);
      this.containerTarget?.removeEventListener(
        "mouseleave",
        this.desactiveSwiper
      );
    }
  }
}