import ApplicationController from "../.././application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for OfferReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["exchange"];

  initialize() {
    this.updateTradesIndexHandler = (eventName) => {
      this.updateTrades();
    };
  }

  connect() {
    super.connect();
    this.sidebar_right = document.querySelector("#right_sidebar");
    window.disputeUploaded = this.disputeUploaded;
    window.disputeUploadedMultipleFiles = this.disputeUploadedMultipleFiles;

    document.addEventListener("update-trades-index", this.updateTradesIndexHandler);
  }

  disconnect() {
    document.removeEventListener("update-trades-index", this.updateTradesIndexHandler);
  }

  updateTrades() {
    setTimeout(() => {
      this.stimulate("P2P::TradesReflex#update_trades_and_sidebar", this.element);
    }, 200);
  }

  disputeUploaded(data) {
    let target = document.getElementById("proof_of_dispute");
    let payload = JSON.stringify(data.filesUploaded[0]);

    target.value = payload;
    target.dispatchEvent(new Event("dispute_uploaded"));
  }

  disputeUploadedMultipleFiles(data) {
    let target = document.getElementById("proof_of_dispute");
    let payload = JSON.stringify(data.filesUploaded);

    target.value = payload;
    target.dispatchEvent(new Event("dispute_uploaded"));
  }

  async closeTradesColumn() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.stimulate("ChatReflex#hidden");
        resolve(true);
      }, 100);
    });
  }

  async showChatTrade(e) {
    e.preventDefault();
    if (!this.hasExchangeTarget) return;
    this.stimulate("ChatReflex#show", this.exchangeTarget);
  }
}
