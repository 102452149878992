document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('disable_totp')

  if (element) {

    new Vue({
      el: element,

      data: {
        show_warnign: false
      }
    })
  }
})
