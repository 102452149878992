import { Controller } from "@hotwired/stimulus";
import { Chart } from 'chart.js/auto'

export default class extends Controller {
  static targets = ['panoramaChart'];

  connect() {
    this.loadGraph();
  }  

  loadGraph() {
    if (!this.hasPanoramaChartTarget) return;

    if (this.panoramaChart) this.panoramaChart.destroy();
    this.panoramaChart = new Chart(this.panoramaChartTarget, {
      type: "line",
      data: {
        labels: this.panoramaChartTarget.dataset.labels.split(';'),
        datasets: [
          {
            label: "Panorama del mercado",
            data: this.panoramaChartTarget.dataset.values.split(';'),
            borderWidth: 2,
            borderColor: "rgba(122, 191, 46, 1)",
            fill: "start",
            backgroundColor: "rgba(122, 191, 46, 0.3)",
            radius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        bezierCurve: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
      },
    });
  }
}
