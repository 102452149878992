import CableReady from 'cable_ready'
import consumer from "./consumer"

// let accountElement = document.getElementById("account")
//
// if(accountElement){
//     consumer.subscriptions.create("AddressChannel", {
//         received(data) {
//             if (data.cableReady) CableReady.perform(data.operations)
//         }
//     });
// }

