import CableReady from 'cable_ready'
import consumer from "./consumer"

var tradeShow = document.getElementById("trade-show")
var tradeChannel;

if(tradeShow) tradeChannel = tradeShow.attributes["trade-channel"].value

consumer.subscriptions.create( { channel: "TradeChannel", trade_channel: tradeChannel }, {
  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
});
