import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
    static targets = [ "mati" ]

    initialize() {
        this.finishedMatiSdk = (event) => {
            this.setVerifyingStatus(event)
        };
    }

    connect() {
        StimulusReflex.register(this);
        const indexView = document.getElementById("verifications-index");
        indexView.addEventListener("kycFinished", this.updateView)

        if (this.hasMatiTarget) {
            this.matiTarget.addEventListener('mati:userFinishedSdk', this.finishedMatiSdk);
        }
    }

    disconnect() {
        if (this.hasMatiTarget) {
            this.matiTarget.removeEventListener('mati:userFinishedSdk', this.finishedMatiSdk);
        }
    }

    openMatiSDK(){
        if(!this.hasMatiTarget) return;

        this.matiTarget.click()
    }

    setVerifyingStatus(event) {
        const userUuid = JSON.parse(event.target.metadata).uuid
        if (!userUuid) return

        this.stimulate("Verification::Kyc#perform_verifying_status", userUuid);
    }

    updateView(){
        location.reload();
    }
}
