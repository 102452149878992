import ApplicationController from "../.././application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for PaymentMethodReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["btnConfirm", "inpCode"];

  connect() {
    super.connect();
  }

  submitPaymentMethodForm() {
    const form = document.getElementById("payment-method-form");
    if (form) form.submit();
  }

  setCode() {
    if (!this.hasBtnConfirmTarget) return;
    const code = this.inpCodeTarget.value.trim()
    this.btnConfirmTarget.dataset.code2fa = code
    const disabled = code == ''
    this.btnConfirmTarget.classList.toggle('opacity-50', disabled);
    this.btnConfirmTarget.toggleAttribute('disabled', disabled);
  }
}
