import ApplicationController from '.././application_controller'
import {formatInput, getDataValue, getNumericValue, setNewValueToTarget} from "../../lib/flip/helpers";

export default class extends ApplicationController {
  static targets = ['mati', 'amountCrypto', 'amountCryptoInputUserCurrency']

  initialize() {
    this.waitingIdentificationHandler = (detail) => {
      this.perform_waiting_identification(detail);
    };
  }

  connect() {
    super.connect();
    if (this.hasMatiTarget) {
      this.loadMatiSDK();
      this.matiTarget.addEventListener("mati:userFinishedSdk", this.waitingIdentificationHandler);
    }
  }

  disconnect() {
    if (this.hasMatiTarget) this.matiTarget.removeEventListener("mati:userFinishedSdk", this.waitingIdentificationHandler);
  }

  perform_waiting_identification(detail){
    this.stimulate("Users::PaymentLinksSecureReflex#waiting_identification", this.element);
  }

  loadMatiSDK() {
    if (document.getElementById('mati-sdk')) return;
    let scriptTag = document.createElement("script");
    scriptTag.src = "https://web-button.getmati.com/button.js";
    scriptTag.id = "mati-sdk";
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
  }

  updateAmountCrypto(event) {
    if (event.detail?.silent || !this.hasAmountCryptoInputUserCurrencyTarget) return;

    const target = event.currentTarget;
    const rate = parseFloat(getDataValue(target, "rate"));
    this.setFixedInput(target);
    if (target == this.amountCryptoTarget){
      let newValue = (getNumericValue(target) * rate).toFixed(2);
      setNewValueToTarget(this.amountCryptoInputUserCurrencyTarget, newValue)
    } else {
      const precision = parseFloat(getDataValue(this.amountCryptoTarget, "precision"));
      const newValue = (getNumericValue(target) / rate).toFixed(precision);
      setNewValueToTarget(this.amountCryptoTarget, newValue)
      this.amountCryptoTarget.dispatchEvent(new CustomEvent("input", { detail: { silent: true }}));
    }
    this.setFormatInputs();
  }

  setFixedInput(target) {
    this.amountCryptoTarget.setAttribute('data-fixed', "false");
    this.amountCryptoInputUserCurrencyTarget.setAttribute('data-fixed', "false");
    target.setAttribute('data-fixed', "true");
  }

  setFormatInputs(){
    if (this.hasAmountCryptoTarget) {
      formatInput(this.amountCryptoTarget);
    }
    if (this.hasAmountCryptoInputUserCurrencyTarget) {
      formatInput(this.amountCryptoInputUserCurrencyTarget);
    }
  }
}
